import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Card } from "reactstrap";
import hompageLogo from "../../../assets/images/mainPageLogo.png";
import mobileNavLogo from "../../../assets/images/mobileNavLogo.png";
import HomeHeader from "./HomeHeader";

export default function Cookie() {
  return (
    <>
      <div className="homePage">
        <HomeHeader />
        <section className="hospoStars newStars">
          <div className="container" style={{ paddingBottom: "20px" }}>
            <div className="row">
              <div className="col text-center paddingBottom"></div>
            </div>
            <Card className="paddingAll paddingBottom0">
              <div className="row">
                <div className="col">
                  <h1 className="Aeonik-Bold">Cookie Policy</h1>
                </div>
              </div>
              <hr />
            </Card>
          </div>
        </section>
      </div>
    </>
  );
}
