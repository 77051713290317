const local = {
  apiUrl: "http://localhost:8989",
  encryptionkey: "",
  PORT: "/api",
  API_BASEURL: "http://localhost:8989",
  SOCKET_URL: "http://localhost:3000", // Use 'wss' protocol for WebSocket
  Frontend_Url: "http://localhost:3000", // Update to the correct frontend URL
};

/**
 * 
*/

const staging = {
  apiUrl: "https://gotipme.com",
  encryptionkey: "",
  PORT: "/api",
  API_BASEURL: "https://gotipme.com/api",
  SOCKET_URL: "wss://gotipme.com/api/socket", // Use 'wss' protocol for WebSocket
  Frontend_Url: "https://gotipme.com", // Update to the correct frontend URL
};

if (process.env.REACT_APP_ENV === "local") module.exports = local;
else if (process.env.REACT_APP_ENV === "staging") module.exports = staging;
module.exports = staging;
