import HomeHeader from "../../homePages/common/HomeHeader";
import { useParams } from "react-router-dom";
import {
  getRestrodetailsByid,
  AddGroupTip,
} from "../../customerInterface/Service/Auth.Service";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_BASEURL } from "../../../environment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";

function GroupTippingPage() {
  const restroId = useParams();
// 
  const [error, setErrors] = useState({});
  const [restroDetails, setRestroDetails] = useState();
  const [total, setTotal] = useState(0);
  const [totalstaffgivenamount, setTotalStaffGivenAmount] = useState(0);
  const [customer_rating_to_staff, setCustomer_rating_to_staff] = useState(0);

  const navigate = useNavigate();
  const userdata = useSelector((state) => state);
  let LoggedIn = userdata?.login?.isLoggedIn;
  const resto_id = restroId?.id;
  let customerId = userdata?.login?.data?.result?._id;
  const dispatch = useDispatch();

  let loginRole = userdata?.login?.data?.role;
  useEffect(() => {
    getByrestroId();
    if (loginRole == 0) {
      navigate("/super-admin/dashboard");
    }
  }, [dispatch]);

  const getByrestroId = () => {
    getRestrodetailsByid(
      { _id: restroId?.id },
      (data) => {
        setRestroDetails(data?.data?.result);
      },
      dispatch
    );
  };
  let guestTipvar = 0;

  {
    LoggedIn == true ? (guestTipvar = 0) : (guestTipvar = 1);
  }

  const [values, set_values] = useState({
    customer_id: customerId,
    is_group_tip: 1,
    tip_type: 1,
    customer_tip_amount: 0,
    donation_amount: 0,
    service_charge: 20,
    customer_comment: "",
    is_customer_commented: 1,
    group_tip_status: 1,
    guest_email: "",
    guest_name: "",
    guest_mobile: "",
    is_guest_registerd_user: 1,

    is_guest_tip: guestTipvar,
  });

  const ratingChanged = (newRating) => {
    setCustomer_rating_to_staff(newRating);
  };

  const values_handler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    const newValues = {
      ...values,
      [name]: value,
    };
    set_values(newValues); // Calling the method to sum the value
    calc_total(newValues);
    totalStaffGivenAmount(newValues);
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };

  const calc_total = (newValues) => {
    const { customer_tip_amount, donation_amount } = newValues;
    const newTotal = Number(customer_tip_amount) + Number(donation_amount);
    setTotal(newTotal);
  };

  const totalStaffGivenAmount = (newValues) => {
    const { customer_tip_amount, service_charge } = newValues;
    const newTotal1 = Number(customer_tip_amount) - Number(service_charge);
    setTotalStaffGivenAmount(newTotal1);
  };
  /*--------@ ButtonHandler for Add group tip----------- */

  const buttonHandler = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      const total_tip_amount = total;
      const total_staff_given_amount = totalstaffgivenamount;

      const res = await AddGroupTip(
        resto_id,
        values.customer_id,
        values.customer_tip_amount,
        total_tip_amount,
        values.donation_amount,
        values.service_charge,
        values.customer_comment,
        values.is_customer_commented,
        values.tip_type,
        values.is_group_tip,
        values.group_tip_status,
        values.guest_email,
        values.guest_name,
        values.guest_mobile,
        values.is_guest_registerd_user,
        values.is_guest_tip,
        total_staff_given_amount,
        customer_rating_to_staff,
        dispatch
      );
      if (res?.data.status == true) {
        {
          LoggedIn == true
            ? navigate(`/customer/card-details/${res.data?.result?._id}`)
            : navigate(
                `/guest/card-details/${res.data?.guestId}/${res.data?.result?._id}`
              );
        }
      } else {
        toast.error(
          "Sorry, something went wrong",
          {
            theme: "dark",
          },
          {
            position: toast.POSITION.TOP_CENTER,
          },
          { autoClose: 1000 }
        );
      }
    }
  };
  const validateForm = () => {
    const errors = {};

    if (!values.customer_tip_amount) {
      errors.customer_tip_amount = "Tip amount is required";
    } else if (values.customer_tip_amount <= 0) {
      errors.customer_tip_amount = "Tip Amount must be greater than zero";
    }

    if (LoggedIn == false) {
      if (!values.guest_name) {
        errors.guest_name = "Full name is required";
      }

      if (!values.guest_email) {
        errors.guest_email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(values.guest_email)) {
        errors.guest_email = "Email is invalid";
      }

      if (!values.guest_mobile) {
        errors.guest_mobile = "Contact No. is required";
      }
    }

    return errors;
  };

  const handeblur = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };
  let ProfilePath = `${API_BASEURL}/public/posts/${restroDetails?.image}`;
  const buttonHandlerpage = async (data) => {
    navigate(`/customer/restaurantprofilescreen/${data?._id}`);
  };

  const mapStyles = {
    height: "25vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: restroDetails?.lat || 51.901516,
    lng: restroDetails?.lng || -8.468283,
  };

  const locations = [
    {
      name: "Cask Pub and Kitchen",
      location: {
        lat: restroDetails?.lat || 51.901516,
        lng: restroDetails?.lng || -8.468283,
      },
    },

    ,
  ];
  return (
    <div className="staffProfile">
      <HomeHeader />
      <div className="container">
        <div className="row my-4">
          <div className="col direction">
            <p class="mb-0 whiteText">
             <a onClick={()=>navigate(`/customer/restaurantprofilescreen/${resto_id}`)}> Home</a> <i class="fa-solid fa-angles-right"></i>
              {restroDetails?.restaurant_name}{" "}
              {/* <i class="fa-solid fa-angles-right"></i> */}
              {/* {restroDetails?.addressLine1},{restroDetails?.city}{" "} */}
            </p>
          </div>
        </div>
        <div className="row profileData">
          <div className="col-lg-6">
            <div className="profileContent">
              <div className="image">
                <img src={ProfilePath} alt="" />
              </div>
              <div className="text">
                <h1> {restroDetails?.restaurant_name}</h1>
                <p>
                  {" "}
                  {/* {restroDetails?.addressLine1}, */}
                  {restroDetails?.city}{" "}
                </p>
                <div className="review">
             
                  <p className="mb-0"></p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 text-end mt-4 mt-lg-0">
            {/* <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            >
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={defaultCenter}
              >
                {locations.map((item) => {
                  return <Marker key={item.name} position={item.location} />;
                })}
              </GoogleMap>
            </LoadScript> */}
          </div>
          <div className="col-lg-6"></div>
        </div>
        {LoggedIn == false ? (
          <div className="ProfileSetting">
            <div className="container">
              <div className="row profileSettingConent">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="inputField">
                        <label htmlFor="userName">Fullname</label>
                        <div className="inputBox">
                          <input
                            type="text"
                            className="form-control px-3"
                            placeholder="Fullname"
                            name="guest_name"
                            onChange={values_handler}
                            onBlur={(e) => handeblur()}
                          />
                        </div>
                        {error.guest_name && (
                          <span className="text-danger">
                            {error.guest_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="inputField">
                        <label htmlFor="userName">Email id</label>
                        <div className="inputBox">
                          <input
                            type="email"
                            className="form-control px-3"
                            placeholder="Email"
                            name="guest_email"
                            onChange={values_handler}
                            onBlur={(e) => handeblur()}
                          />
                        </div>
                        {error.guest_email && (
                          <span className="text-danger">
                            {error.guest_email}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="inputField">
                        <label htmlFor="contact">Contact No</label>
                        <div className="inputBox countryCodeBox">
                          <input
                            type="text"
                            className="form-control px-3 countryCode"
                            id="country"
                            value={"+44"}
                          />
                          <input
                            type="text"
                            className="form-control px-3"
                            id="contact"
                            placeholder="Contact Number"
                            name="guest_mobile"
                            onChange={values_handler}
                            onBlur={(e) => handeblur()}
                          />
                        </div>
                        {error.guest_mobile && (
                          <span className="text-danger">
                            {error.guest_mobile}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row TippingBox" >
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="inputField-inline">
                  <label for="tipping" className="form-label">
                    Tip Amount ($) :
                  </label>
                  <div className="inputBox">
                    <input
                      type="number"
                      className="form-control"
                      name="customer_tip_amount"
                      id="inputEmail4"
                      onChange={values_handler}
                      onBlur={(e) => handeblur()}
                    />
                    {error.customer_tip_amount && (
                      <span className="text-danger">
                        {error.customer_tip_amount}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="inputField-inline">
                  <label for="donation" className="form-label">
                    Donation ($) <span>(Optional)</span> :
                  </label>
                  <div className="inputBox">
                    <input
                      type="number"
                      class="form-control"
                      id="donation"
                      name="donation_amount"
                      onChange={values_handler}
                    />
                    {error.donation_amount && (
                      <span className="text-danger">
                        {error.donation_amount}
                      </span>
                    )}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip2">
                          This amount will be sent to the admin of GoTipMe.
                        </Tooltip>
                      }
                    >
                      <i class="fa-solid fa-circle-info"></i>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="inputField-inline bgLight">
                  <label for="totalAmount" className="form-label">
                    Total Amount ($) :
                  </label>
                  <div className="inputBox">
                    <input
                      disabled
                      type="number"
                      class="form-control plane"
                      id="totalAmount"
                      value={
                        values?.donation_amount
                          ? total
                          : values?.customer_tip_amount
                      }
                      onChange={values_handler}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-12">
                <div className="inputField-inline">
                  <label className="form-label">Rating :</label>
                  <div className="inputBox">
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={30}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ffd700"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="inputField-inline align-items-start">
                  <label for="totalAmount" className="form-label">
                    Add Feedback:
                  </label>
                  <div className="inputBox">
                    <textarea
                      name="customer_comment"
                      id=""
                      cols="50"
                      rows="4"
                      className="form-control"
                      onChange={values_handler}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
<br />
        <div className="row buttonsRow paddingBottom marginNone"  >
          <div className="navBtn"></div>
          <div className="btnGroups">
            <button
              className="btn btn-white-outline btn-height"
              onClick={(e) => buttonHandlerpage(restroDetails)}
            >
              Cancel
            </button>
            <button className="btn btn-main btn-height" onClick={buttonHandler}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupTippingPage;
