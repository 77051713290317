import React, { useContext, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DefaultImage from "../../../assets/images/defaultimage.png";
import { userlogout } from "../../auth/loginSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getByUserProfile } from "../../homePages/Service/AuthService";
import { SocketContext } from "../../../socket/SocketContext";

function Header() {
  const [userShowProfile, setShowUserProfile] = useState();
  const socket = useContext(SocketContext);
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    _getUsershowProfile();
  }, [dispatch]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.clear();
    dispatch(userlogout());
    navigate("/login");
  };
  const reduxData = useSelector((state) => state);
  let user_id = reduxData?.login?.data?.result?._id;

  const [notifications, setNotifications] = useState();
  useEffect(() => {
    if (socket) {
      socket.emit("super-admin-login", user_id);
      socket.on("super-admin-notifications-list", (newData) => {
        setNotifications(newData);
        setHasNewNotification(true);
      });
      socket.on("receive-tipdistribute", (newData) => {
        setNotifications(newData);
        setHasNewNotification(true);
      });
    }
    return () => {
      // socket.off("foo", getNotificationList)
    };
  }, [socket, notifications]);

  // const _getUsershowProfile = () => {
  //   getByUserProfile(
  //     { _id: user_id },
  //     (data) => {
  //       setShowUserProfile(data[0]);
  //     },
  //     dispatch
  //   );
  // };

    const _getUsershowProfile = async () => {
      const response = await getByUserProfile({ _id: user_id });
      setShowUserProfile(response);
    };

  const formatNotificationDate = (createdAt) => {
    const notificationTime = new Date(createdAt);
    const now = new Date();
    const timeDiffInSeconds = Math.floor((now - notificationTime) / 1000);

    if (timeDiffInSeconds < 60) {
      return `${timeDiffInSeconds} seconds ago`;
    } else if (timeDiffInSeconds < 3600) {
      const minutes = Math.floor(timeDiffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (timeDiffInSeconds < 86400) {
      const hours = Math.floor(timeDiffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      const year = notificationTime.getFullYear();
      const month = padNumber(notificationTime.getMonth() + 1);
      const day = padNumber(notificationTime.getDate());
      return `${year}-${month}-${day}`;
    }
  };

  const padNumber = (number) => {
    return number.toString().padStart(2, "0");
  };
  return (
    <>
      <header className="admin-header">
        <ul>
          <li>
            <Dropdown>
              <Dropdown.Toggle variant="none" className="notificatio-dropdown">
                <i class="fa-solid fa-bell"></i>

                {hasNewNotification && (
                  <div className="notification-badge-admin">
                    <i className="fa-solid"></i>
                  </div>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu className="notification-menu admin-notification-menu">
                <Dropdown.Header className="notification-header">
                  Notifications
                </Dropdown.Header>
                {notifications?.map((notification, index) => (
                  <Dropdown.Item
                    className="notification-list"
                    key={notification?._id}
                  >
                    <div className="notification-item ">
                    <p className="admin-notification-message">{notification?.message}</p>

                      {formatNotificationDate(notification?.createdAt)}

                      {index !== notifications.length - 1 && (
                        <hr className="notification-divider" />
                      )}
                    </div>
                  </Dropdown.Item>
                ))}

                {/* <Dropdown.Item href="#/action-1">

                You have one message

                </Dropdown.Item> */}

                {/* <Link to="">View All</Link> */}
                <Link
                                to="/super-admin/adminnotification-list"
                                className="custom-link view-all-link"
                                style={{ color: "green" }}
                              >
                                View All
                              </Link>
              </Dropdown.Menu>
             
            </Dropdown>
          </li>
          <li>
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                className="login-dropdown"
                id="dropdown-basic"
              >
                <div className="user-name">
                  <img src={DefaultImage} />
                  <h6>
                    {userShowProfile?.full_name} <span>GoTipMe </span>
                  </h6>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item href="#/action-1">
                <i class="fa-solid fa-user"></i> Profile
                </Dropdown.Item>
                <Dropdown.Item ><i class="fa-solid fa-gear"></i> Setting</Dropdown.Item> */}
                <Dropdown.Item onClick={handleLogout}>
                  <i class="fa-solid fa-right-from-bracket"></i> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </header>
    </>
  );
}

export default Header;
