import React, { useEffect, useState } from "react";
import {
  getCustomerTipList,
  NoofStaffTipinglist,
  getnoCustomerTipList,
  StaffGroupTipingHistory,
  Customerandstafftiphistory,
} from "../../staffInterface/service/Auth.service";
import { useDispatch, useSelector } from "react-redux";
import { pagination } from "../../../components/common/utils/message";
import "react-toastify/dist/ReactToastify.css";
import { API_BASEURL } from "../../../environment";
import { useNavigate } from "react-router-dom";
import HomeHeader from "../../homePages/common/HomeHeader";
import ReactPaginate from "react-paginate";
import DefaultImage from "../../../assets/images/defaultimage.png";

function TippingHistory() {
  const [historyState, setHistoryState] = useState(0);
  const [totalPages, settotalPages] = useState();
  const [staffTipingHistory, setStaffTipingHistory] = useState();
  const [groupTipingHistory, setGroupTipingHistory] = useState();
  const [searchText, setSearchText] = useState("");
  const [customerTippingHistory, setCustomerTippingHistory] = useState();
  const [customertotalPages, setCustomerTotalPages] = useState();
  const [NoofcustomerTippingHistory, setNoofCustomerTippingHistory] =
    useState();
  const [grouphistorytotalpages, setGrouphistoryTotalpages] = useState();

  const [no_of_TippingHistory, setNo_of_TippingHistory] = useState();
  const [selectedOption, setSelectedOption] = useState("option1");
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const maxDate = new Date().toISOString().split("T")[0];
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };
  const userdata = useSelector((state) => state);
  // let staff_id = userdata?.login?.data?.result._id;

  let staff_id;
  let loginRole = userdata?.login?.data?.role;
  let user_id;
  if (loginRole == 3) {
    staff_id = userdata?.login?.data?.result?._id;
  }
  if (loginRole == 0) {
    staff_id = userdata?.adminstaff?.data?._id;
  }

  //let group_staff_id =userdata?.login?.data?.result._id
  let group_staff_id = staff_id;
  useEffect(() => {
    _getStaffTippingHistory();
    _getCustomerTippingHistory();
    _getGroupTippingHistory();
    _getNoofStaffTippingHistory();
    _getNoofCustomerTippingHistory();
  }, [dispatch]);

  const _getNoofStaffTippingHistory = () => {
    NoofStaffTipinglist(
      { page, limit, staff_id },
      (data) => {
        setNo_of_TippingHistory(data);
      },
      dispatch
    );
  };

  const _getStaffTippingHistory = () => {
    Customerandstafftiphistory(
      {
        page,
        limit,
        group_staff_id,
        staff_id,
        start_date,
        end_date,
        searchText,
      },
      (data) => {
        setStaffTipingHistory(data?.docs);
        // settotalPages(data?.totalPages);
      },
      dispatch
    );
  };

  let filtered;

  // const handleStaffTippingPageChange = async (param) => {
  //   let currentPage = param.selected + 1;
  //   setPage(currentPage);
  //   await StaffTipingHistory(
  //     { staff_id, page: currentPage, limit },
  //     (response) => {
  //       setStaffTipingHistory(response?.docs);
  //     },
  //     dispatch
  //   );
  // };
  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  const handleInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    // _getStaffTippingHistory();
  };

  const __handleStaffandCustomerTipSearch = async (e) => {
    setSearchText(e.target.value);
    _getStaffTippingHistory();
  };
  // -------------------------------------------------------------

  const _getCustomerTippingHistory = (searchText) => {
    getCustomerTipList(
      { page, limit, staff_id, start_date, end_date, searchText: searchText },
      (data) => {
        setCustomerTippingHistory(data?.data?.result?.docs);
        setCustomerTotalPages(data?.data?.result?.totalPages);
      },
      dispatch
    );
  };
  const handleCustomerTippingPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getCustomerTipList(
      { staff_id, page: currentPage, limit },
      (response) => {
        setCustomerTippingHistory(response?.data?.result?.docs);
      },
      dispatch
    );
  };

  const _getNoofCustomerTippingHistory = () => {
    getnoCustomerTipList(
      { page, limit, staff_id },
      (data) => {
        setNoofCustomerTippingHistory(data?.data?.result);
      },
      dispatch
    );
  };

  function handleCustomerOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  const handleCustomerInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleCustomerInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getCustomerTippingHistory();
  };
  const __handleCustomerTipSearch = async (e) => {
    setSearchText(e.target.value);
    _getCustomerTippingHistory(e.target.value);
  };
  // --------------------------------------------------------------------------------

  let tipingDataResult;

  const _getGroupTippingHistory = (searchText) => {
    StaffGroupTipingHistory(
      {
        page,
        limit,
        group_staff_id: staff_id,
        start_date,
        end_date,
        searchText: searchText,
      },
      (data) => {
        // tipingDataResult =   data.docs.filter(item => item._id == staff_id);
        setGroupTipingHistory(data?.docs);
        setGrouphistoryTotalpages(data?.totalPages);
      },
      dispatch
    );
  };

  const handleGroupTipPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await StaffGroupTipingHistory(
      { group_staff_id: staff_id, page: currentPage, limit },
      (response) => {
        setGroupTipingHistory(response?.docs);
      },
      dispatch
    );
  };

  const __handleGroupTipSearch = async (e) => {
    setSearchText(e.target.value);
    _getGroupTippingHistory(e.target.value);
  };

  const handleGroupInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleGroupInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getGroupTippingHistory();
  };

  var total = 0;
  for (var i = 0; i < no_of_TippingHistory?.length; i++) {
    total += no_of_TippingHistory[i]?.total_tip_amount;
  }
  var totalcustomertip = 0;
  for (var i = 0; i < NoofcustomerTippingHistory?.length; i++) {
    totalcustomertip += NoofcustomerTippingHistory[i]?.staff_tip_amount;
  }
  var totalGrouptip = 0;
  for (var i = 0; i < groupTipingHistory?.length; i++) {
    totalGrouptip += groupTipingHistory[i]?.group_staff[0]?.group_staff_amount;
  }

  const buttonviewHandlerpage = async (data) => {
    navigate(`/staff/viewprofile/${data?._id}`);
  };
  return (
    <div className="tippingHistroy blackBackground">
      <HomeHeader />
      <div className="container-fluid">
        <div className="row topBar">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content">
                    <div className="heading">
                      <h3>Tipping History</h3>
                    </div>
                    <div className="price">
                      <h3>£ {Math.round(totalcustomertip)}</h3>
                      <p>Total Tips Paid</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="tabs">
              <div
                className={`tab ${historyState === 0 && "active"}`}
                onClick={onTabClick(0)}
              >
                Total Tips
                <div className="line"></div>
              </div>
              <div
                className={`tab ${historyState === 1 && "active"}`}
                onClick={onTabClick(1)}
              >
                PubGoer Tips
                <div className="line"></div>
              </div>
              <div
                className={`tab ${historyState === 2 && "active"}`}
                onClick={onTabClick(2)}
              >
                Group Tips
                <div className="line"></div>
              </div>
              {/* <div className={`tab ${historyState === 2 && "active"}`} onClick={onTabClick(2)}>
                                Group Tips
                                <div className="line"></div>
                            </div> */}
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>£ {Math.round(totalcustomertip)}</h3>
                <p>Total Tips Paid</p>
              </div>
              <div className="dates">
                {/* <div className="dropdown">
                  <select
                    name=""
                    id=""
                    className="form-select"
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                  </select>
                </div> */}
                <div className="datePicker">
                  <label htmlFor="pickFrom">From</label>
                  <input
                    type="date"
                    name=""
                    id="pickFrom"
                    max={maxDate}
                    className="form-control"
                    onChange={handleInputStartDate}
                  />
                </div>
                <div className="datePicker">
                  <label htmlFor="pickTo">To</label>
                  <input
                    type="date"
                    name=""
                    id="pickTo"
                    max={maxDate}
                    className="form-control"
                    onChange={handleInputEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row searchStaff">
              <div className="col-12 px-0">
                <div className="searchBox">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search"
                    value={searchText}
                    onChange={__handleStaffandCustomerTipSearch}
                  />
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {customerTippingHistory &&
                  customerTippingHistory?.map((data, index) => {
                    let ProfilePath = `${API_BASEURL}/public/profile/${data?.customerDetails[0]?.profile}`;

                    return (
                      <>
                        <div className="webView">
                          <div key={index} className="staffProfileList ">
                            <div className="firstdata">
                              <div className="image">
                                {/* <img src="../../assets/startProfile.png" alt="" /> */}

                                <img
                                  src={
                                    data?.customerDetails[0]?.profile != null ||
                                    data?.customerDetails[0]?.profile !=
                                      undefined
                                      ? ProfilePath
                                      : DefaultImage
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="text">
                                <p className="mb-0">
                                  Recieved from {""}
                                  <span className="fw-bold">
                                    {/* {data?.customerDetails[0]?.full_name} */}
                                    {data?.is_guest_tip == false
                                      ? data?.customerDetails[0]?.full_name
                                      : data?.guest?.guest_name}
                                  </span>{" "}
                                  ,{""}
                                </p>

                                <div className="date">
                                  <p className="mb-0">
                                    {data?.restroDetails?.restaurant_name}
                                  </p>
                                </div>
                              </div>
                              <div className="date">
                                <p className="mb-0">{data?.createdAt}</p>
                              </div>
                            </div>
                            <div className="text">
                              <p className="mb-0 dflex alc r">
                                <h3
                                  className="Aeonik-Regular"
                                  style={{
                                    color: "#f4f4f4",
                                    marginBottom: "0px",
                                  }}
                                >
                                  £{""}
                                  {data?.staff_tip_amount}
                                </h3>
                                <a
                                  onClick={(e) => buttonviewHandlerpage(data)}
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <i class="fa-solid fa-chevron-right"></i>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          key={index}
                          className="staffProfileList mobileView"
                        >
                          <div className="firstdata">
                            <div className="image">
                              <img
                                src={
                                  data?.customerDetails[0]?.profile != null ||
                                  data?.customerDetails[0]?.profile != undefined
                                    ? ProfilePath
                                    : DefaultImage
                                }
                                alt=""
                              />
                            </div>
                            <div className="text">
                              <p className="mb-0">
                                Recieved from {""}
                                <span className="fw-bold">
                                  {data?.is_guest_tip == false
                                    ? data?.customerDetails[0]?.full_name
                                    : data?.guest?.guest_name}
                                </span>{" "}
                                ,{""}
                              </p>

                              <div className="date">
                                <p className="mb-0">
                                  {data?.restroDetails?.restaurant_name}
                                </p>
                              </div>
                            </div>
                            <div className="date">
                              <p className="mb-0">{data?.createdAt}</p>
                            </div>
                          </div>
                          <div className="text">
                            <p className="mb-0 dflex alc r">
                              <h3
                                className="Aeonik-Regular"
                                style={{
                                  color: "#f4f4f4",
                                  marginBottom: "0px",
                                }}
                              >
                                £{""}
                                {data?.staff_tip_amount.toFixed(2)}
                              </h3>
                              <a
                                onClick={(e) => buttonviewHandlerpage(data)}
                                style={{ paddingLeft: "10px" }}
                              >
                                <i class="fa-solid fa-chevron-right"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {customerTippingHistory?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {customertotalPages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleCustomerTippingPageChange}
                      pageCount={customertotalPages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 1 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>£ {Math.round(totalcustomertip)}</h3>
                <p>Total Tips Paid</p>
              </div>
              <div className="dates">
                {/* <div className="dropdown">
                  <select
                    name=""
                    id=""
                    className="form-select"
                    onChange={handleCustomerOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                  </select>
                </div> */}
                <div className="datePicker">
                  <label htmlFor="pickFrom">From</label>
                  <input
                    type="date"
                    name=""
                    id="pickFrom"
                    max={maxDate}
                    className="form-control"
                    onChange={handleCustomerInputStartDate}
                  />
                </div>
                <div className="datePicker">
                  <label htmlFor="pickTo">To</label>
                  <input
                    type="date"
                    name=""
                    id="pickTo"
                    max={maxDate}
                    className="form-control"
                    onChange={handleCustomerInputEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row searchStaff">
              <div className="col-12 px-0">
                <div className="searchBox">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search customers"
                    value={searchText}
                    onChange={__handleCustomerTipSearch}
                  />
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {customerTippingHistory &&
                  customerTippingHistory?.map((data, index) => {
                    let ProfilePath = `${API_BASEURL}/public/profile/${data?.customerDetails[0]?.profile}`;

                    return (
                      <>
                        <div className="webView">
                          <div key={index} className="staffProfileList">
                            <div className="firstdata">
                              <div className="image">
                                {/* <img src="../../assets/startProfile.png" alt="" /> */}

                                <img
                                  src={
                                    data?.customerDetails[0]?.profile != null ||
                                    data?.customerDetails[0]?.profile !=
                                      undefined
                                      ? ProfilePath
                                      : DefaultImage
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="text">
                                <p className="mb-0">
                                  Recieved from {""}
                                  <span className="fw-bold">
                                    {/* {data?.customerDetails[0]?.full_name} */}
                                    {data?.is_guest_tip == false
                                      ? data?.customerDetails[0]?.full_name
                                      : data?.guest?.guest_name}
                                  </span>{" "}
                                  ,{""}
                                </p>

                                <div className="date">
                                  <p className="mb-0">
                                    {data?.restroDetails?.restaurant_name}
                                  </p>
                                </div>
                              </div>
                              <div className="date">
                                <p className="mb-0">{data?.createdAt}</p>
                              </div>
                            </div>
                            <div className="text">
                              <p className="mb-0 dflex alc">
                                <h3
                                  className="Aeonik-Regular"
                                  style={{
                                    color: "#f4f4f4",
                                    marginBottom: "0px",
                                  }}
                                >
                                  £{""}
                                  {data?.staff_tip_amount.toFixed(2)}
                                </h3>
                                <a
                                  onClick={(e) => buttonviewHandlerpage(data)}
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <i class="fa-solid fa-chevron-right"></i>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          key={index}
                          className="staffProfileList mobileView"
                        >
                          <div className="firstdata">
                            <div className="image">
                              {/* <img src="../../assets/startProfile.png" alt="" /> */}

                              <img
                                src={
                                  data?.customerDetails[0]?.profile != null ||
                                  data?.customerDetails[0]?.profile != undefined
                                    ? ProfilePath
                                    : DefaultImage
                                }
                                alt=""
                              />
                            </div>
                            <div className="text">
                              <p className="mb-0">
                                Recieved from {""}
                                <span className="fw-bold">
                                  {/* {data?.customerDetails[0]?.full_name} */}
                                  {data?.is_guest_tip == false
                                    ? data?.customerDetails[0]?.full_name
                                    : data?.guest?.guest_name}
                                </span>{" "}
                                ,{""}
                              </p>

                              <div className="date">
                                <p className="mb-0">
                                  {data?.restroDetails?.restaurant_name}
                                </p>
                              </div>
                            </div>
                            <div className="date">
                              <p className="mb-0">{data?.createdAt}</p>
                            </div>
                          </div>
                          <div className="text">
                            <p className="mb-0 dflex alc">
                              <h3
                                className="Aeonik-Regular"
                                style={{
                                  color: "#f4f4f4",
                                  marginBottom: "0px",
                                }}
                              >
                                £{""}
                                {data?.staff_tip_amount}
                              </h3>
                              <a
                                onClick={(e) => buttonviewHandlerpage(data)}
                                style={{ paddingLeft: "10px" }}
                              >
                                <i class="fa-solid fa-chevron-right"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {customerTippingHistory?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {customertotalPages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleCustomerTippingPageChange}
                      pageCount={customertotalPages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 2 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>£ {Math.round(totalGrouptip)}</h3>
                <p>Total Tips Paid</p>
              </div>
              <div className="dates">
                <div className="datePicker">
                  <label htmlFor="pickFrom">From</label>
                  <input
                    type="date"
                    name=""
                    id="pickFrom"
                    max={maxDate}
                    className="form-control"
                    onChange={handleGroupInputStartDate}
                  />
                </div>
                <div className="datePicker">
                  <label htmlFor="pickTo">To</label>
                  <input
                    type="date"
                    name=""
                    id="pickTo"
                    max={maxDate}
                    className="form-control"
                    onChange={handleGroupInputEndDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row searchStaff">
              <div className="col-12 px-0">
                <div className="searchBox">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search restaurants"
                    value={searchText}
                    onChange={__handleGroupTipSearch}
                  />
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {groupTipingHistory &&
                  groupTipingHistory?.map((data, index) => {
                    let ProfilePath = `${API_BASEURL}/public/posts/${data?.restroDetails?.image}`;

                    return (
                      <>
                        <div className="webView">
                          <div key={index} className="staffProfileList">
                            <div className="firstdata">
                              <div className="image">
                                {/* <img src="../../assets/startProfile.png" alt="" /> */}

                                <img src={ProfilePath} alt="" />
                              </div>
                              <div className="text">
                                <p className="mb-0">
                                  Recieved from {""}
                                  <span className="fw-bold">
                                    {data?.restroDetails?.restaurant_name}
                                  </span>{" "}
                                  ,{""}
                                </p>
                              </div>
                              <div className="date">
                                <p className="mb-0">{data?.createdAt}</p>
                              </div>
                            </div>
                            <div className="text">
                              <p className="mb-0 dflex alc">
                                <h3
                                  className="Aeonik-Regular"
                                  style={{
                                    color: "#f4f4f4",
                                    marginBottom: "0px",
                                  }}
                                >
                                  £{""}
                                  {data?.group_staff[0]?.group_staff_amount?.toFixed(
                                    2
                                  )}
                                </h3>
                                <a
                                  onClick={(e) => buttonviewHandlerpage(data)}
                                  style={{ paddingLeft: "10px" }}
                                >
                                  <i class="fa-solid fa-chevron-right"></i>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          key={index}
                          className="staffProfileList mobileView"
                        >
                          <div className="firstdata">
                            <div className="image">
                              {/* <img src="../../assets/startProfile.png" alt="" /> */}

                              <img src={ProfilePath} alt="" />
                            </div>
                            <div className="text">
                              <p className="mb-0">
                                Recieved from {""}
                                <span className="fw-bold">
                                  {data?.restroDetails?.restaurant_name}
                                </span>{" "}
                                ,{""}
                              </p>
                            </div>
                            <div className="date">
                              <p className="mb-0">{data?.createdAt}</p>
                            </div>
                          </div>
                          <div className="text">
                            <p className="mb-0 dflex alc">
                              <h3
                                className="Aeonik-Regular"
                                style={{
                                  color: "#f4f4f4",
                                  marginBottom: "0px",
                                }}
                              >
                                £{""}
                                {data?.group_staff[0]?.group_staff_amount?.toFixed(
                                  2
                                )}
                              </h3>
                              <a
                                onClick={(e) => buttonviewHandlerpage(data)}
                                style={{ paddingLeft: "10px" }}
                              >
                                <i class="fa-solid fa-chevron-right"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {groupTipingHistory?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {grouphistorytotalpages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleGroupTipPageChange}
                      pageCount={grouphistorytotalpages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TippingHistory;
