import HomeHeader from "../../homePages/common/HomeHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { getRestroById } from "../../staffInterface/service/Auth.service";
import { API_BASEURL } from "../../../environment";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function RestoViewProfile() {
  const [viewProfileData, setViewProfileData] = useState();
  const dispatch = useDispatch();
  const restroId = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    getByusersId();
  }, [dispatch]);

  const getByusersId = () => {
    getRestroById(
      { _id: restroId.id },
      (data) => {
        setViewProfileData(data);
      },
      dispatch
    );
  };

  let ProfilePath = `${API_BASEURL}/public/posts/${viewProfileData?.image}`;

  const handleUpdate = (_id) => {
    // setCancelledrestroRequest(_id);
  };

  const mapStyles = {
    height: "25vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: viewProfileData?.lat || 51.901516,
    lng: viewProfileData?.lng || -8.468283,
  };

  const locations = [
    {
      name: "Cask Pub and Kitchen",
      location: {
        lat: viewProfileData?.lat || 51.901516,
        lng: viewProfileData?.lng || -8.468283,
      },
    },

    ,
  ];
  const handleBack = () => {
    navigate("/staff/invitationrequest");
  };
  return (
    <div className="RIAddAddress">
      {/* <Header /> */}
      <HomeHeader />
      <div className="container">
        <div className="row my-4">
          <div className="col">{/* <SearchFilter /> */}</div>
        </div>
        <div className="row my-4">
          <div className="col direction">
            <p class="mb-0 ">
              <Link to="/staff/dashboard" className="custom-link">
                {" "}
                Home <i class="fa-solid fa-angles-right"></i>
              </Link>
              {viewProfileData?.restaurant_name}{" "}
              <i class="fa-solid fa-angles-right"></i>
              {/* <span>
                {viewProfileData?.addressLine1}, {viewProfileData?.city} (
                {viewProfileData?.postcode})
              </span> */}
            </p>
          </div>
        </div>
        <div className="row ProfileDesign">
          <div className="col-lg-4">
            <img src={ProfilePath} alt="" className="w-100 mainImage" />
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <h1 className="Aeonik-Bold">{viewProfileData?.restaurant_name}</h1>
            <p className="Aeonik-Regular">
              <i className="fa-solid fa-location-dot"></i>{" "}
              {/* {viewProfileData?.addressLine1}, */}
              <span className="Aeonik-Regular" >
                {viewProfileData?.city} ({viewProfileData?.postcode})
              </span>
            </p>
            <p className="Aeonik-Regular">
              <i class="fa-solid fa-envelope"></i>{" "}
              {viewProfileData?.email}
            </p>
            <p className="Aeonik-Regular">
              <i class="fa-solid fa-phone"></i> +44{" "}
              {viewProfileData?.restaurantContact_number}
            </p>
            <div className="review">
              {/* <div className="star">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star-half"></i>
              </div>
              <p className="mb-0">(4.5)</p> */}
            </div>
          </div>
          <div className="col-lg-4 text-end mt-4 mt-lg-0">
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            >
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={defaultCenter}
              >
                {locations.map((item) => {
                  return <Marker key={item.name} position={item.location} />;
                })}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
        <div className="row EditDetails mt-2">
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            {/* <p>Are the Restaurant details incorrect?</p> */}
          </div>
          <div className="col-lg-4 text-end"></div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <div className="buttonsRow">
              <div className="navBtn"></div>
              <div className="btnGroups">
                {/* <button
                  className="btn btn-white-outline"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={(e) => {
                    handleUpdate(viewProfileData?._id, e.target.value);
                  }}
                >
                 Cancel Request
                </button> */}
              </div>
              <button
                className="btn btn-height btn-white-outline me-2"
                onClick={handleBack}
              >
                Back
              </button>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                <form action="">
                  <div className="row">
                    <div className="col-10 text-start m-auto">
                      <label htmlFor="writeConcern">Write your concern</label>
                      <textarea
                        name=""
                        id="writeConcern"
                        cols="30"
                        rows="5"
                        className="form-control my-2"
                      ></textarea>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <button
                        className="btn btn-white-outline me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button className="btn btn-main">Send to GoTipMe</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default RestoViewProfile;
