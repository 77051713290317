// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { sendPaymentResponse } from "../Service/Auth.Service"; // API service function
// import Header from "../../homePages/common/HomeHeader";
// import faq from "../../../assets/images/askedQuestions.png";

// function PaymentConfirmation() {
//   const [confirmationMessage, setConfirmationMessage] = useState("");
//   const [transactionId, setTransactionId] = useState(null);
//   const [paymentStatus, setPaymentStatus] = useState(null);

//   const location = useLocation();
//   const getQueryParams = (query) => {
//     const queryParams = new URLSearchParams(query);
//     const params = {};

//     queryParams.forEach((value, key) => {
//       params[key] = value;
//     });

//     return params;
//   };

//   const updatePaymentStatus = async (data) => {
//     try {
//       const response = await sendPaymentResponse(data);
//       console.log("Payment response:", response.data);
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   // 1. Update values from localStorage and query params
//   useEffect(() => {
//     const queryParams = getQueryParams(location.search);
//     console.log("queryparams----", queryParams);
//     const status = queryParams.code === "paid" ? true : false;
//     const txId = queryParams.tx_id;
//     setTransactionId(txId);
//     setPaymentStatus(status);

//     setConfirmationMessage(
//       queryParams.code === "paid"
//         ? "Payment Successful. Thank you!"
//         : "Payment Cancelled"
//     );
//   }, [location.search]);

//   // 2. Trigger API call only after transactionId , and paymentStatus are updated
//   useEffect(() => {
//     if (transactionId && paymentStatus !== null) {
//       console.log("All values are available, making API call...");
//       updatePaymentStatus({
//         transaction_id: transactionId,
//         trustap_code: paymentStatus ? "paid" : "cancelled",
//       });
//     } else {
//       console.log("Waiting for values to be updated...", {
//         transactionId,
//         paymentStatus,
//       });
//     }
//   }, [transactionId, paymentStatus]);

//   return (
//     <div>
//       <div className="homePage">
//         <Header />
//         <div
//           className="row contactSection"
//           id="contactUs"
//           style={{
//             marginBottom: "25px",
//           }}
//         >
//           <div className="col-md-12 text-center">
//             <h1 className="Spoof-Black">{confirmationMessage}</h1>
//           </div>
//         </div>
//         <div className="col-md-12 text-center">
//           <img src={faq} alt="" className="fitContent" />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default PaymentConfirmation;

// With Loader in progress

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { sendPaymentResponse } from "../Service/Auth.Service"; // API service function
import Header from "../../homePages/common/HomeHeader";
import faq from "../../../assets/images/askedQuestions.png";
import LoaderUI from "../../../components/common/loader/loader";

function PaymentConfirmation() {
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [transactionId, setTransactionId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(false); // Loader state

  const location = useLocation();

  const getQueryParams = (query) => {
    const queryParams = new URLSearchParams(query);
    const params = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  // Function to update payment status and handle the response
  const updatePaymentStatus = async (data) => {
    try {
      setLoading(true); // Start loader
      const response = await sendPaymentResponse(data);
      if (response?.data?.data?.payment_status === true) {
        setConfirmationMessage("Payment Successful. Thank you!");
        // localStorage.setItem(
        //   "paymentMessage",
        //   "Payment Successful. Thank you!"
        // ); // Save message in localStorage
      } else {
        setConfirmationMessage("Payment Cancelled");
        // localStorage.setItem("paymentMessage", "Payment Cancelled"); // Save message in localStorage
      }
      setLoading(false); // Stop loader
    } catch (error) {
      console.error("Error:", error);
      setConfirmationMessage("Pay Again");
      setLoading(false); // Stop loader in case of error
    } finally {
      setLoading(false); // Stop loader in any case
    }
  };

  // Update values from query params and localStorage on mount
  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const status = queryParams.code === "paid" ? true : false;
    const txId = queryParams.tx_id;
    setTransactionId(txId);
    setPaymentStatus(status);

    setConfirmationMessage(
      queryParams.code !== "paid" && "Payment Cancelled"
    );
  }, [location.search]);

  // Trigger API call when transactionId and paymentStatus are available
  useEffect(() => {
    if (transactionId && paymentStatus !== null) {
      updatePaymentStatus({
        transaction_id: transactionId,
        trustap_code: paymentStatus ? "paid" : "cancelled",
      });
    }
  }, [transactionId, paymentStatus]);

  return (
    <div>
      <div className="homePage">
        <Header />
        <div
          className="row contactSection"
          id="contactUs"
          style={{
            marginBottom: "25px",
          }}
        >
          <div className="col-md-12 text-center">
            {/* Display loader while waiting for the response */}
            {loading ? (
              <LoaderUI /> // Show loader component while loading
            ) : (
              <h1 className="Spoof-Black">
                {/* {console.log("Confirmation message:", confirmationMessage)} */}
                {confirmationMessage}
              </h1>
            )}
          </div>
        </div>
        {/* Only display the image when not loading */}
        {!loading && (
          <div className="col-md-12 text-center">
            <img src={faq} alt="" className="fitContent" />
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentConfirmation;
