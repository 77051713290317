import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { userlogout } from "../../auth/loginSlice";
import { useSelector, useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { API_BASEURL } from "../../../environment";
import { getByUserProfile } from "../Service/AuthService";
import { SocketContext } from "../../../socket/SocketContext";
import { markNotificationAsRead } from "../../../services/auth.service";
import { Dropdown, Button } from "react-bootstrap";
import hompageLogo from "../../../assets/images/mainPageLogo.png";
import mobileNavLogo from "../../../assets/images/mobileNavLogo.png";

// import io from "socket.io-client";
// import { SocketContext } from "../../../context/SocketContext";
// export const socket = io("http://localhost:8989");
function HomeHeader() {
  const [userShowProfile, setShowUserProfile] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const userdata = useSelector((state) => state);
  const rolePermission = userdata?.login?.data?.role;

  const [hasNewNotification, setHasNewNotification] = useState(false);

  useEffect(() => {
    _getUsershowProfile();
  }, [dispatch]);

  const reduxData = useSelector((state) => state);
  let user_id = reduxData?.login?.data?.result?._id;

  let reduxUser = reduxData?.login;

  let reduxUserRole = reduxData?.login?.data?.result?.role;

  let strHref = window.location.href;

  let urlSecondElement = strHref.split("/").reverse()[1];

  let urlThirdElement = strHref.split("/").reverse()[2];

  const handleLogoClick = () => {
    if (reduxUserRole == 1) {
      navigate("/customer/dashboard");
    } else if (reduxUserRole == 2) {
      navigate("/resto/mainrestodashboard");
    } else if (reduxUserRole == 3) {
      navigate("/staff/dashboard");
    }
  };

  const [notifications, setNotifications] = useState();
  const getNotificationList = () => {
    socket.emit("join", userdata?.login?.data?.result?._id);
    if (reduxUserRole == 2) {
      socket.on("receive-request", (newData) => {
        setNotifications(newData);
        setHasNewNotification(true);
      });
    }
    if (reduxUserRole == 3) {
      socket.on("accepted-request", (newData) => {
        setNotifications(newData);
        setHasNewNotification(true);
      });
      socket.on("rejected-request", (newData) => {
        setNotifications(newData);
        setHasNewNotification(true);
      });
    }
    socket.on("receive-tip", (newData) => {
      setNotifications(newData);
      setHasNewNotification(true);
    });
    socket.on("guest-receive-tip", (newData) => {
      setNotifications(newData);
      setHasNewNotification(true);
    });
  };

  useEffect(() => {
    if (socket) {
      socket.emit("loginevent", userdata?.login?.data?.result?._id);
      socket.on("notifications-list", (newData) => {
        setNotifications(newData);
      });
      getNotificationList();
    }
    return () => {
      // socket.off("foo", getNotificationList)
    };
  }, [socket, notifications]);

  // const formatNotificationDate = (createdAt) => {
  //   const notificationTime = new Date(createdAt);
  //   const year = notificationTime.getFullYear();
  //   const month = padNumber(notificationTime.getMonth() + 1);
  //   const day = padNumber(notificationTime.getDate());
  //   return `${year}-${month}-${day}`;
  // };

  const formatNotificationDate = (createdAt) => {
    const notificationTime = new Date(createdAt);
    const now = new Date();
    const timeDiffInSeconds = Math.floor((now - notificationTime) / 1000);

    if (timeDiffInSeconds < 60) {
      return `${timeDiffInSeconds} seconds ago`;
    } else if (timeDiffInSeconds < 3600) {
      const minutes = Math.floor(timeDiffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (timeDiffInSeconds < 86400) {
      const hours = Math.floor(timeDiffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      const year = notificationTime.getFullYear();
      const month = padNumber(notificationTime.getMonth() + 1);
      const day = padNumber(notificationTime.getDate());
      return `${year}-${month}-${day}`;
    }
  };

  const padNumber = (number) => {
    return number.toString().padStart(2, "0");
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.clear();
    dispatch(userlogout());
    navigate("/login");
  };

  // const _getUsershowProfile = () => {
  //   getByUserProfile(
  //     { _id: user_id },
  //     (data) => {
  //       setShowUserProfile(data);
  //     },
  //     dispatch
  //   );
  // };

  const _getUsershowProfile = async () => {
    const response = await getByUserProfile({ _id: user_id });
    setShowUserProfile(response);
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      // Make an API call to update the notification status
      await markNotificationAsRead(notificationId);
      const updatedNotifications = notifications?.map((notification) => {
        if (notification._id === notificationId) {
          return { ...notification, read: true };
        }
        return notification;
      });
      setNotifications(updatedNotifications);
    } catch (error) {
      // Handle error
      console.error("Failed to mark notification as read:", error);
    }
  };

  let ProfilePath = `${API_BASEURL}/public/profile/${userShowProfile?.profile}`;

  const location = useLocation();

  // Common handler for navigation with scroll
  const handleNavigateWithScroll = (targetSection) => {
    if (location.pathname !== "/") {
      // If not on the home page, navigate to home first
      navigate("/", { replace: true });
      setTimeout(() => {
        window.location.href = targetSection; // Scroll to the target section after navigating
      }, 100); // Slight delay to ensure the page loads before scrolling
    } else {
      // If already on the home page, directly scroll to the target section
      window.location.href = targetSection;
    }
  };

  return (
    <>
      {reduxUser?.isLoggedIn == true ? (
        <div className="header">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid px-0">
                <a className="navbar-brand">
                  <div className="logo">
                    <Link to={"/"}>
                      <img
                        src={hompageLogo}
                        style={{ borderRadius: "20px" }}
                        className="webView"
                        height={50}
                        width={100}
                        alt=""
                      />
                      {/* <img
                        src={mobileNavLogo}
                        style={{ borderRadius: "20px" }}
                        className="mobileView"
                        height={50}
                        width={100}
                        alt=""
                      /> */}
                    </Link>
                  </div>
                </a>
                <button
                  className="navbar-toggler btn-height"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  {/* <span className="navbar-toggler-icon"></span> */}
                  <i className="fa-solid fa-bars text-dark"></i>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  {reduxUserRole == 1 ||
                  (reduxUserRole == 0 &&
                    (urlSecondElement == "customer" ||
                      urlThirdElement == "customer")) ? (
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      {reduxUserRole == 0 ? (
                        <li className="nav-item">
                          {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Home </Link> */}
                          <Link
                            className="nav-link active"
                            aria-current="page"
                            to="/super-admin/dashboard"
                          >
                            Admin Dashboard
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      <li className="nav-item">
                        {/* <Link to="/customer/dashboard" className="btn btn-main">Home</Link> */}
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/customer/dashboard"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/customer/dashboard" className="btn btn-main">Tipping History</Link> */}
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/customer/tippinghistory"
                        >
                          Tipping History
                        </Link>
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/customer/dashboard" className="btn btn-main">Tipping History</Link> */}
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/customer/customerTipReport"
                        >
                          Tip Report
                        </Link>
                      </li>
                      {reduxUserRole == 1 ? (
                        <li className="nav-item">
                          {/* <Link to="/customer/dashboard" className="btn btn-main"> Contact Support</Link> */}
                          <Link className="nav-link " to="/customer/contactus">
                            Contact Support
                          </Link>
                          <Link
                            className="nav-link nav_desk"
                            to="/profilesetting"
                          >
                            Profile
                          </Link>
                          <a
                            className="nav-link nav_desk"
                            onClick={handleLogout}
                          >
                            Logout
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  ) : (
                    " "
                  )}
                  {reduxUserRole == 2 ||
                  (reduxUserRole == 0 &&
                    (urlSecondElement == "resto" ||
                      urlThirdElement == "resto")) ? (
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      {reduxUserRole == 0 ? (
                        <li className="nav-item">
                          {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Home </Link> */}
                          <Link
                            className="nav-link active"
                            aria-current="page"
                            to="/super-admin/dashboard"
                          >
                            Admin Dashboard
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      <li className="nav-item">
                        {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Home </Link> */}
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/resto/mainrestodashboard"
                        >
                          Pub Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Roles And Permissions</Link> */}
                        {/* <a
                        className="nav-link"
                        aria-current="page"
                        href="/resto/mainrestodashboard"
                      >
                        Roles And Permissions
                      </a> */}
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Manage Staff</Link> */}
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/resto/managestaff"
                        >
                          Manage Pubstars
                        </Link>
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Invitation Request </Link> */}
                        {/* <Link
                          className="nav-link"
                          aria-current="page"
                          to="/resto/invitationrequest"
                        >
                          Pubstar Requests
                        </Link> */}
                        <a
                          className="nav-link"
                          aria-current="page"
                          href="#managePhotos"
                          onClick={() => navigate("/resto/dashboard")}
                        >
                          Manage Photos
                        </a>
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Tipping Management</Link> */}
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/resto/tipmanagement"
                        >
                          Group Tips
                        </Link>
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Reports</Link> */}
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/resto/report"
                        >
                          Reports
                        </Link>
                      </li>
                      {reduxUserRole == 2 ? (
                        <li className="nav-item">
                          {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Contact Support</Link> */}
                          <Link className="nav-link" to="/resto/contactus">
                            Contact Support
                          </Link>
                          <Link
                            className="nav-link nav_desk"
                            to="/resto/dashboard"
                          >
                            Profile
                          </Link>
                          <a
                            className="nav-link nav_desk"
                            onClick={handleLogout}
                          >
                            Logout
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  ) : (
                    " "
                  )}
                  {reduxUserRole == 3 ||
                  (reduxUserRole == 0 &&
                    (urlSecondElement == "staff" ||
                      urlThirdElement == "staff")) ? (
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      {reduxUserRole == 0 ? (
                        <li className="nav-item">
                          {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Home </Link> */}
                          <Link
                            className="nav-link active"
                            aria-current="page"
                            to="/super-admin/dashboard"
                          >
                            Admin Dashboard
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      <li className="nav-item">
                        {/* <Link to="/staff/dashboard" className="btn btn-main">Home</Link> */}
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/staff/dashboard"
                        >
                          Pubstar Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/staff/dashboard" className="btn btn-main">Home</Link> */}
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/staff/staffPost"
                        >
                          Public Profile
                        </Link>
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/staff/dashboard" className="btn btn-main">Tipping History</Link> */}
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/staff/tippinghistory"
                        >
                          Tipping History
                        </Link>
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/staff/dashboard" className="btn btn-main">Invitation Request</Link> */}
                        {/* {console.log(
                          ".......... rolePermission",
                          rolePermission
                        )} */}
                        {rolePermission == 3 ? (
                          <Link
                            className="nav-link"
                            aria-current="page"
                            to="/staff/StaffHome"
                          >
                            Add your workplace
                          </Link>
                        ) : (
                          <Link
                            className="nav-link"
                            aria-current="page"
                            to="/staff/invitationrequest"
                          >
                            Invitation Request
                          </Link>
                        )}
                      </li>
                      <li className="nav-item">
                        {/* <Link to="/staff/dashboard" className="btn btn-main">My Previous Job</Link> */}
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/profilesetting?connect_payment=true"
                        >
                          Connect Payment method
                        </Link>
                      </li>

                      <li className="nav-item">
                        {/* <Link to="/resto/mainrestodashboard" className="btn btn-main">Invitation Request </Link> */}
                        {/* <Link
                          className="nav-link"
                          aria-current="page"
                          to="/resto/invitationrequest"
                        >
                          Pubstar Requests
                        </Link> */}
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/staff/staffPost?tabValue=1"
                        >
                          Manage Photos
                        </Link>
                      </li>
                      {reduxUserRole == 3 ? (
                        <li className="nav-item">
                          {/* <Link to="/staff/dashboard" className="btn btn-main">Contact Support</Link> */}
                          <Link className="nav-link" to="/staff/contactus">
                            Contact Support
                          </Link>
                          <Link
                            className="nav-link nav_desk"
                            to="/profilesetting"
                          >
                            Profile
                          </Link>
                          <a
                            className="nav-link nav_desk"
                            onClick={handleLogout}
                          >
                            Logout
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  ) : (
                    " "
                  )}

                  <div className="profile">
                    {/* {reduxUserRole == 1 ? null : (
                    <div className="bell dropdown">
                      <div
                        className="image dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="bell">
                          <i className="fa-solid fa-bell"></i>
                          {hasNewNotification && (
                            <div className="notification-badge">
                              <i className="fa-solid"></i>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <h5>Notifications</h5>
                        {notifications?.length > 0 ? (
                          <div
                            className="notification-list"
                            style={{
                              maxHeight: "300px",
                              overflowY: "auto",
                            }}
                          >
                            {notifications?.map((notification, index) => (
                              <>
                                {!notification.read && (
                                  <div
                                    key={index}
                                    className="notification-item"
                                  >
                                    <a
                                      href=""
                                      className="notification-message custom-link"
                                    >
                                      <h6>{notification?.message}</h6>
                                      <span className="notification-date">
                                        {formatNotificationDate(
                                          notification.createdAt
                                        )}
                                      </span>
                                    </a>

                                    <button
                                      onClick={() =>
                                        handleMarkAsRead(notification?._id)
                                      }
                                    >
                                      Mark as Read
                                    </button>
                                  </div>
                                )}
                              </>
                            ))}
                            <Link
                              to="/notificationlist"
                              className="custom-link"
                              style={{ color: "green" }}
                            >
                              View All
                            </Link>
                          </div>
                        ) : (
                          <a className="dropdown-item" href="/">
                            No new notifications
                          </a>
                        )}
                      </div>
                    </div>
                    )} */}

                    {/* {reduxUserRole == 1 ? null : (
                      <Dropdown className="bell ">
                        <Dropdown.Toggle
                          className="notification-dropdown "
                          id="dropdownMenuButton1"
                        >
                          <div className="bell blackText">
                            <i className="fa-solid fa-bell blackText"></i>
                            {(hasNewNotification ||
                              notifications?.some(
                                (notification) => !notification.read
                              )) && <div className="notification-badge"></div>}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="notification-menu">
                          <Dropdown.Header className="notification-header">
                            Notifications
                          </Dropdown.Header>
                          {notifications?.length > 0 ? (
                            <div className="notification-list">
                              {notifications?.map((notification, index) => (
                                <div key={index}>
                                  {!notification.read && (
                                    <div className="notification-item">
                                      <h6>{notification?.message}</h6>

                                      <div className="mark-as-read-button-container">
                                        <div>
                                          <span className="notification-date">
                                            {formatNotificationDate(
                                              notification.createdAt
                                            )}
                                          </span>
                                        </div>
                                        <div>
                                          <Button
                                            variant="primary"
                                            onClick={() =>
                                              handleMarkAsRead(
                                                notification?._id
                                              )
                                            }
                                            className="mark-as-read-button btn-height"
                                          >
                                            Mark as Read
                                          </Button>
                                        </div>
                                      </div>

                                      {index !== notifications.length - 1 && (
                                        <hr className="notification-divider" />
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                              <Link
                                to="/notificationlist"
                                className="custom-link view-all-link"
                                style={{ color: "green" }}
                              >
                                View All
                              </Link>
                            </div>
                          ) : (
                            <Dropdown.Item className="no-notification-item">
                              No new notifications
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )} */}
                    <div className="profileImage dropdown">
                      <div
                        className="image dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            userShowProfile?.profile != null ||
                            userShowProfile?.profile != undefined
                              ? ProfilePath
                              : userShowProfile?.image != null ||
                                userShowProfile?.image != undefined
                              ? API_BASEURL +
                                "/public/posts/" +
                                userShowProfile?.image
                              : "https://static.vecteezy.com/system/resources/thumbnails/010/260/479/small/default-avatar-profile-icon-of-social-media-user-in-clipart-style-vector.jpg"
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link
                          className="dropdown-item Aeonik-Bold"
                          to={
                            reduxUserRole == 2
                              ? "/resto/dashboard"
                              : "/profilesetting"
                          }
                        >
                          Profile
                        </Link>
                        <a
                          className="dropdown-item Aeonik-Bold "
                          onClick={handleLogout}
                        >
                          Logout
                        </a>
                      </div>

                      {/* <div className="text">
                        <p className="mb-0 Aeonik-Bold">
                          {reduxUserRole == 2
                            ? userShowProfile?.restaurant_name
                            : userShowProfile?.full_name}
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>

          {reduxUserRole == 0 ? (
            <div>
              {" "}
              <Link
                className="nav-link active"
                aria-current="page"
                to="/super-admin/dashboard"
              >
                {" "}
                <h4 style={{ textAlign: "center" }}> Super Admin</h4>{" "}
              </Link>
              {urlSecondElement == "resto" || urlThirdElement == "resto" ? (
                <h5 style={{ textAlign: "center" }}>
                  Pub : {userdata?.adminrestro?.data?.restaurant_name}
                </h5>
              ) : (
                ""
              )}
              {urlSecondElement == "staff" || urlThirdElement == "staff" ? (
                <h5 style={{ textAlign: "center" }}>
                  Pub Star : {userdata?.adminstaff?.data?.full_name}
                </h5>
              ) : (
                ""
              )}
              {urlSecondElement == "customer" ||
              urlThirdElement == "customer" ? (
                <h5 style={{ textAlign: "center" }}>
                  Customer : {userdata?.admincustomer?.data?.full_name}
                </h5>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row headerSection">
          <div className="col">
            <div className="header without_login">
              <div className="container">
                <nav className="navbar navbar-expand-lg">
                  <div className="container-fluid px-0">
                    <Link className="navbar-brand" to="/">
                      <img
                        src={hompageLogo}
                        alt=""
                        className="webView"
                        height={50}
                        style={{ borderRadius: "20px" }}
                      />{" "}
                      {/* <img
                          src={mobileNavLogo}
                          style={{ borderRadius: "20px" }}
                          className="mobileView"
                          height={50}
                          width={100}
                          alt=""
                        /> */}
                    </Link>
                    <button
                      // className="navbar-toggler btn-main "
                      className="navbar-toggler btn-height "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      style={{ background: "#faf14b", color: "black" }}
                    >
                      {/* <span className="navbar-toggler-icon text-light"></span> */}
                      <i className="fa-solid fa-bars blackText "></i>
                    </button>

                    <div
                      className="collapse navbar-collapse justify-content-end"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav mb-2 mb-lg-0">
                        {/* Contact Us Link */}
                        <li className="nav-item hide_mobile">
                          <Link
                            to="/contactUs"
                            className="nav-link custom-link"
                          >
                            Contact
                          </Link>
                        </li>

                        {/* How It Works Link */}
                        <li className="nav-item hide_mobile">
                          <a
                            className="nav-link highlighted"
                            href="#howItWorks"
                            onClick={(e) => {
                              e.preventDefault();
                              handleNavigateWithScroll("#howItWorks");
                            }}
                          >
                            How It Works
                          </a>
                        </li>

                        {/* Sign Up Link */}
                        <li className="nav-item">
                          <a
                            className="nav-link highlighted"
                            href="#AccountCreate"
                            onClick={(e) => {
                              e.preventDefault();
                              handleNavigateWithScroll("#AccountCreate");
                            }}
                          >
                            Sign Up
                          </a>
                        </li>

                        {/* Login Button */}
                        <li className="nav-item">
                          <Link to="/login" className="btn btn-main btn-height">
                            Login
                          </Link>
                        </li>
                      </ul>
                      <form className="d-flex"></form>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HomeHeader;




                  // <div
                  //   className="collapse navbar-collapse justify-content-end"
                  //   id="navbarSupportedContent"
                  // >
                  //   <ul className="navbar-nav mb-2 mb-lg-0">
                  //     <li className="nav-item hide_mobile">
                  //       <a className="nav-link" href="#contactUs">
                  //         <Link to="/contactUs" className="custom-link">
                  //           Contact
                  //         </Link>
                  //       </a>
                  //     </li>
                  //     <li className="nav-item hide_mobile">
                  //       {/* <Link className="nav-link" to="/">
                  //             How It Works
                  //           </Link> */}
                  //       <a className="nav-link highlighted" href="#howItWorks">
                  //         How It Works
                  //       </a>
                  //     </li>
                  //     <li className="nav-item">
                  //       <a
                  //         className="nav-link highlighted"
                  //         href="#AccountCreate"
                  //       >
                  //         {/* <Link to="/Register" className="custom-link">{" "} */}
                  //         {/* <Link to="/" className="nav-link highlighted"> */}
                  //         {/* {" "} */}
                  //         Sign Up
                  //         {/* </Link> */}
                  //       </a>
                  //     </li>
                  //     <li className="nav-item">
                  //       <Link to="/login" className="btn btn-main btn-height">
                  //         Login
                  //       </Link>
                  //     </li>
                  //   </ul>
                  //   <form className="d-flex"></form>
                  // </div>;