import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from "query-string";
import { loadingAction } from "../components/common/loader/LoaderSlice";
import { customAction } from "../components/common/customToaster/CustomToasterSlice";

export const addProjects = (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": `multipart/form-data`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .post(`${apiUrl}${PORT}/rpc/project/create`, data, option)
                .then((response) => {
                    // console.log(" add project response", response);
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
export const listContractorProjects = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/contractor/projects?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
export const listProjects = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/rpc/project/view/all?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const contClosedOppourtunity = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/contractor/project/getclosedProjects?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contPurchasedProject = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/contractor/project/getPurchasedProjects?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const project_details = async (callback) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .get(`${apiUrl}${PORT}/rpc/project/details`, option)
                .then((response) => {
                    callback(response.data.data);
                })
                .catch((error) => {
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const bids = async (id, callback) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .post(`${apiUrl}${PORT}/rpc/bids/view/all`, id, option)
                .then((response) => {
                    callback(response.data.data);
                })
                .catch((error) => {
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const sendInvitationToContrator = (data, callback) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .post(`${apiUrl}${PORT}/rpc/bids/send-invite`, data, option)
                .then((response) => {
                    callback(response.data);
                })
                .catch((error) => {
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
export const sendInvitation = (id, callback) => {
    try {
        console.log(id);
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .post(`${apiUrl}${PORT}/rpc/bid-invitation/send`, id, option)
                .then((response) => {
                    callback(response.data);
                })
                .catch((error) => {
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const listSendBidInvitation = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/contractor/bid-invitation/list`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const DeleteProject = async (id, callback) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .delete(`${apiUrl}${PORT}/rpc/project/delete/${id}`, option)
                .then((response) => {
                    callback(response.data);
                })
                .catch((error) => {
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateProject = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": `multipart/form-data`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .put(`${apiUrl}${PORT}/rpc/project/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const viewOneProject = async (param, callback) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .get(`${apiUrl}${PORT}/rpc/project/view?${queryString.stringify(param)}`, option)
                .then((response) => {
                    callback(response.data.data);
                })
                .catch((error) => {
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const addMedia = (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": `multipart/form-data`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .post(`${apiUrl}${PORT}/rpc/media/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description Soft Delete document in db
 * @param {*} id
 * @param {*} callback
 */
export const deleteDocument = async (id, callback) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .delete(`${apiUrl}${PORT}/rpc/media/delete/${id}`, option)
                .then((response) => {
                    callback(response.data);
                })
                .catch((error) => {
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const emailBidderProject = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/rpc/bidden/view/all?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const listBidForm = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/rpc/project/viewBidForm/${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const listBidFormData = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/rpc/project/viewBidForm/${param}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const addBidForm = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .post(`${apiUrl}${PORT}/rpc/project/bidForm/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateBidForm = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .put(`${apiUrl}${PORT}/rpc/project/bidForm/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateContractorBidForm = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .post(`${apiUrl}${PORT}/api/v1/contractor/project/submitBid`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const listBidProject = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/rpc/project/viewBidForm/${param}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    // callback(response.data)
                    callback(response.data.data.line_items);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const submitBidFormContractor = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .post(`${apiUrl}${PORT}/contractor/project/submitBid`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const getContractorSumbitBid = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/contractor/project/mybidDetails/${param}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    // callback(response.data)
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const getSumbitBidAdmin = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/admin/project/bidSubmission/list/${param}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    // callback(response.data)
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const listMasterBidForm = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/admin/masterLineItems/list`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const createBidForm = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .post(`${apiUrl}${PORT}/admin/project/bidForm/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/*
 * @description get all bidden project list for accordia and bid result page.
 * @param {*} param
 * @param {*} callback
 */
export const listBidResultAccordian = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/admin/project/bidSubmission/list/${param}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/*
 * @description get bid form list for particular bidform project.
 * @param {*} param
 * @param {*} callback
 */
export const listBidFormAdmin = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/admin/project/viewBidForm/${param}`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const awardProjectForm = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .post(`${apiUrl}${PORT}/admin/project/bidSubmission/award`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
export const AddNewLineItems = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .post(`${apiUrl}${PORT}/admin/masterLineItems/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const AddMasterDescription = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .post(`${apiUrl}${PORT}/admin/masterProjectDescription/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateMasterLineItems = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .put(`${apiUrl}${PORT}/admin/masterLineItems/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const listMasterProjectDescription = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .get(`${apiUrl}${PORT}/admin/masterProjectDescription/list`, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateBidFormContractor = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true));
            axios
                .put(`${apiUrl}${PORT}/contractor/project/updateBid`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false));
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false));
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

// export const allGraph = async (callback) => {
//   console.log(" IN service allGraph ------");
//   try {
//     let token = localStorage.getItem("authentication");
//     if (token) {
//       let option = {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       };
//       // dispatch(loadingAction(true))
//       axios
//         .get(`${apiUrl}${PORT}/admin/graph_reports/generate`, option)
//         .then((response) => {
//           // console.log(' allGraph allGraph  Response ------ ',response)
//           //  dispatch(loadingAction(false))
//             console.log("see", response)
//           callback(response);
//         })
//         .catch((error) => {
//           //  dispatch(loadingAction(false))
//           callback(false);
//         });
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

export const allGraph = async (data, callback) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .post(`${apiUrl}${PORT}/admin/graph_reports/generate`, data, option)
                .then((response) => {
                    callback(response.data);
                })
                .catch((error) => {
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const project_details_data = async (callback) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            axios
                .get(`${apiUrl}${PORT}/admin/getDashboardData`, option)
                .then((response) => {
                    callback(response.data.data);
                })
                .catch((error) => {
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const  customToastUpdate = async (dispatch)=>{
    dispatch(customAction(true))
     setTimeout(() => {
        dispatch(customAction(false))
    }, 2000);
     
}
