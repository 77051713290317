import HomeHeader from "../../homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_BASEURL } from "../../../environment";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getMyPreviousJobList } from "../service/Auth.service";
import { pagination, } from "../../../components/common/utils/message";
import ReactPaginate from "react-paginate";

function MyPreviousJob() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(pagination.projectLimit);
    const [page, setPage] = useState(pagination.page);
    const [previuosJobList, setPreviousJobLIst] = useState();
    const [totalPages, settotalPages] = useState();
    const userdata = useSelector((state) => state);
    //  let staff_id = userdata?.login?.data?.result?._id;
    let staff_id;
    let loginRole = userdata?.login?.data?.role;
    let user_id;
    if (loginRole == 3) {
        staff_id = userdata?.login?.data?.result?._id;
    }
    if (loginRole == 0) {
        staff_id = userdata?.adminstaff?.data?._id;
    }

    useEffect(() => {
        getPreviouJobList();
    }, [dispatch]);

    const getPreviouJobList = () => {
        getMyPreviousJobList(
            { staff_id, page, limit },
            (data) => {
                setPreviousJobLIst(data?.data?.result?.docs);
                settotalPages(data?.data?.result?.totalPages);
            },
            dispatch
        );
    };
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage);
        await getMyPreviousJobList(
            { staff_id, page: currentPage, limit },
            (response) => {
                setPreviousJobLIst(response?.data?.result?.docs);
            },
            dispatch
        );
    };

    const buttonviewHandlerpage = async (data) => {
        navigate(`/staff/previousjobprofile/${data?._id}`);
    };
    return (
        <div className="StaffHomeScreen">
            <HomeHeader />

            <section className="rearchResults">
                <div className="container">
                    <div className="row my-5">
                        <div className="col-12">
                            <h2 className="whiteText Aeonik-Bold">My Previous Job</h2>
                        </div>
                        <div className="col-12">
                            {previuosJobList &&
                                previuosJobList?.map((data, index) => {
                                    let ProfilePath = `${API_BASEURL}/public/posts/${data?.restroDetails?.image}`;

                                    return (
                                        <div key={index} className="pubDetails whiteBackground">
                                            <div className="image dflex alc">
                                                <img src={ProfilePath} alt="" />
                                            </div>
                                            <div className="text">
                                                <h3 className="Aeonik-Bold">{data?.restroDetails?.restaurant_name}</h3>
                                                <p className="mb-1 Aeonik-Regular">
                                                    {/* <i className="fa-solid fa-location-dot blackText"></i> {data?.restroDetails?.addressLine1}, */}
                                                    <span className="Aeonik-Regular">
                                                        {" "}
                                                        {data?.restroDetails?.city} ({data?.restroDetails?.postcode})
                                                    </span>
                                                </p>
                                                <p className="mb-1 Aeonik-Regular">
                                                    Status - <span>Not Active</span>
                                                </p>
                                                <p className="mb-0 Aeonik-Regular">
                                                    Your Role - <span>Pubstar</span>
                                                </p>
                                            </div>
                                            <div className="next" onClick={(e) => buttonviewHandlerpage(data)}>
                                                <i class="fa-solid fa-arrow-right"></i>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div 
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {previuosJobList?.length == 0
                        ? <p className="whiteText Aeonik-Regular">No Records found</p>
                        : ""}
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            <nav aria-label="Page navigation example" class="paginationRow">
                                {totalPages > 1 ? (
                                    <ReactPaginate
                                        breakLabel={"..."}
                                        nextLabel={"Next >"}
                                        onPageChange={handlePageChange}
                                        pageCount={totalPages}
                                        previousLabel={"< Back"}
                                        renderOnZeroPageCount={null}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                ) : null}
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer /> */}
        </div>
    );
}

export default MyPreviousJob;
