import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Card } from "reactstrap";
import hompageLogo from "../../../assets/images/mainPageLogo.png";
import gotip from "../../../assets/images/gotipLogo.png";
import mobileNavLogo from "../../../assets/images/mobileNavLogo.png";
import HomeHeader from "./HomeHeader";

export default function MainHomeContactUs() {
  const navigate = useNavigate();
  return (
    <>
      <div className="homePage">
        <HomeHeader />
        <div className="container-fluid">
          <div className="row headerSection">
            <div className="col"></div>
          </div>
          <div className="row contactSection" id="contactUs">
            <div
              className="col-md-12 dflex alc"
              style={{ paddingLeft: "30px" }}
            >
              <div className="col-md-12">
                <h1 className="Spoof-Black" style={{ paddingBottom: "30px" }}>
                  About GoTipMe
                </h1>
                <div className="contactForm">
                  <p className="form-group">
                    GoTipMe was created in 2024 by Seamus Travers, off Howl At
                    The Moon pub in Hoxton.
                  </p>
                  <p className="form-group">
                    With a wealth of over 15 years of experience in Hospitality,
                    Seamus Travers identified a need to help pubgoers directly
                    reward bartenders, leading to the creation of GoTipMe.
                  </p>
                  <p className="form-group">
                    GoTipMe is headquartered on Paul Street, close to the
                    Silicon Roundabout and the buzzing bars of Shoreditch.
                  </p>
                  <p className="form-group">
                    Our company aims to establish a platform that delivers
                    bartenders additional compensation while improving the
                    pubgoer's experience.
                  </p>
                  <div className="col-md-6" style={{ paddingTop: "30px" }}>
                    <h3 className="Spoof-Black">Contact GoTipMe</h3>
                    <div className="contactForm">
                      <div className="form-group">
                        <label htmlFor="business_name">Email : </label>{" "}
                        <i>
                          <a href="mailto:hello@gotipme.com">
                            hello@gotipme.com
                          </a>
                        </i>
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone_number">Phone Number : </label>{" "}
                        <i>+44 20 4578 0870</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
