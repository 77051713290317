import axios from "axios";
import { apiUrl, PORT } from "../environment";
import { loadingAction } from "../components/common/loader/LoaderSlice";
import { getInfo } from "../features/auth/service/Auth.header";
import queryString from "query-string";
import { userlogout } from "../features/auth/loginSlice";

// const TOKEN = getInfo();
// let tokenString = localStorage.getItem('user');
// console.log(tokenString,"tokenStringtokenStringtokenStringtokenString");
// // Parse the token string to an object
// // let token = JSON.parse(tokenString);
// let axiosConfig = {
//   headers: {
//     // "Content-Type": "application/json",
//     Authorization: tokenString?.Token,
//   },
// };
// console.log("--------------------------",axiosConfig);

const login = async (email, password, dispatch) => {
  try {
    dispatch(loadingAction(true));

    const response = await axios.post(`${apiUrl}${PORT}/users/signin`, {
      email,
      password,
    });
    if (response.data.status) {
      localStorage.setItem("user", JSON.stringify(response.data));
      dispatch(loadingAction(false));

      return response;
    } else {
      dispatch(loadingAction(false));

      return response;
    }
  } catch (e) {
    dispatch(loadingAction(false));

    return null;
  }
};




const logout = () => {
  localStorage.clear();
};

export const getbyUserid = async (param, callback, dispatch) => {
  try {

    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };

      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/users/getbyid?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          // console.log("adadads" , response)
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {

    console.log(err);
  }
};

export const InvitationRequestList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/invitationrequestlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const addrestroForm = async (formData, callback) => {
  try {
    axios.put(`${apiUrl}${PORT}/users/updateuser`, formData)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        // dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err.message);
  }
};
export const deleteGallery = async (payload, callback) => {
  try {
    axios.post(`${apiUrl}${PORT}/users/deleteUserPost`, payload)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        // dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err.message);
  }
};
export const addUserPost = async (formData, callback) => {
  try {
    axios.put(`${apiUrl}${PORT}/users/addUserPost`, formData)
      .then((response) => {
        // dispatch(loadingAction(false));
        callback(response.data);
      })
      .catch((error) => {
        // dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err.message);
  }
};
export const editGallery = async (formData, callback) => {
  try {
    axios.put(`${apiUrl}${PORT}/users/editGallery`, formData)
      .then((response) => {
        // dispatch(loadingAction(false));
        callback(response.data);
      })
      .catch((error) => {
        // dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err.message);
  }
};



export const AllactiveStaffList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/allactivestafflists?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

const Acceptinvitation = async (_id, is_closed, status) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.put(
        `${apiUrl}${PORT}/restaurant/acceptinvitation`,
        {
          _id,
          is_closed,
          status,
        },
        axiosConfig
      );
      return response;
    }
  } catch (e) {
    // console.log("e", e);
    return null;
  }
};

const Rejectinvitation = async (_id, is_closed, status) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.put(
        `${apiUrl}${PORT}/restaurant/rejectinvitation`,
        {
          _id,
          is_closed,
          status,
        },
        axiosConfig
      );
      return response;
    }
  } catch (e) {
    // console.log("e", e);
    return null;
  }
};

export const AcceptrequestList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/acceptrequestlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const RejectrequestList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/rejectrequestlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const AllstaffList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/allstafflist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const uploadPost = async (formData,type, callback , dispatch) => {
    try {
      dispatch(loadingAction(true));
      console.log("sdasdsa" ,formData )
      axios.post(`${apiUrl}${PORT}/post/uploadPost?type=${type}`, formData)
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${apiUrl}${PORT}/forgetPassword`, email);
    return response.data;
  } catch (e) {
    return e;
  }
};

const resetPassword = (email) => {
  return axios
    .post(`${apiUrl}${PORT}/reset/password`, email)
    .then((response) => {
      return response.data;
    });
};

const verifyToken = (email, token) => {
  return axios
    .post(`${apiUrl}${PORT}/verify/token`, { email, token })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description registration in db.
 * @param {*} data
 * @param {*} callback
 */

export const registration = (data, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    return axios
      .post(`${apiUrl}${PORT}/signup`, data)
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response.data);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description contactUs in db.
 * @param {*} data
 * @param {*} callback
 */
export const contact_us = (data, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    return axios
      .post(`${apiUrl}${PORT}/contact-us`, data)
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response.data);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};

export const changePassword = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .put(`${apiUrl}${PORT}/user/password/update`, data, option)
        .then((response) => {
          dispatch(loadingAction(false));
          callback(true);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description  owner's Profile view.
 * @param {*} param
 * @param {*} callback
 */
export const get_profile = async (callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(`${apiUrl}${PORT}/user/profile/view`, option)
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description  owner's Profile view.
 * @param {*} param
 * @param {*} callback
 */
export const edit_profile = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .put(`${apiUrl}${PORT}/user/profile/update`, param, option)
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const AllNoOfstaffList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/restaurant/allnoofstafflists?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

const staffAcceptinvitation = async (_id, staff_request_accepted) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.put(
        `${apiUrl}${PORT}/restaurant/staffrequestaccepted`,
        {
          _id,
          staff_request_accepted,
        },
        axiosConfig
      );
      return response;
    }
  } catch (e) {
    // console.log("e", e);
    return null;
  }
};

export const NotificationLists = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/notification/getnotificationlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const markNotificationAsRead = async (data) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.put(
        `${apiUrl}${PORT}/notification/updatemarkread`,
        { data },
        axiosConfig
      );
      if (response.data.status) {
        return response;
      } else {
        return response;
      }
    }
  } catch (e) {
    return null;
  }
};

const googleLogin = async (idToken, dispatch) => {
  try {
    // dispatch(loadingAction(true));
    // console.log("fsdafsd" , idToken)

    const response = await axios.post(`${apiUrl}${PORT}/users/googleloginapi`, idToken,
    );
    if (response.data.status) {
      localStorage.setItem("user", JSON.stringify(response.data));
      // dispatch(loadingAction(false));

      return response;
    } else {
      // dispatch(loadingAction(false));

      return response;
    }
  } catch (e) {
    // console.log(e,"e");
    // dispatch(loadingAction(false));

    return null;
  }
};

const facebookLogin = async (data) => {
  try {
    // dispatch(loadingAction(true));

    const response = await axios.post(`${apiUrl}${PORT}/users/facebookloginapi`, 
    data,
  );
    if (response.data.status) {
      localStorage.setItem("user", JSON.stringify(response.data));
      // dispatch(loadingAction(false));

      return response;
    } else {
      // dispatch(loadingAction(false));

      return response;
    }
  } catch (e) {
    // console.log(e,"e");
    // dispatch(loadingAction(false));

    return null;
  }
};

export const checkToken = async ( dispatch , navigate) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      axios
        .get(
          `${apiUrl}${PORT}/users/checkToken?token=${token1?.Token}`
        )
        .then((response) => {
          if(!response.data){
            localStorage.removeItem("user");
            localStorage.clear();
            dispatch(userlogout());
            window.location.href = "/login"
          }
        })
        .catch((error) => {
        });
    }
  } catch (err) {

    console.log(err);
  }
};

const AuthService = {
  login,
  googleLogin,
  facebookLogin,
  logout,
  forgotPassword,
  verifyToken,
  resetPassword,
  changePassword,
  Acceptinvitation,
  Rejectinvitation,
  staffAcceptinvitation,
};

export default AuthService;
