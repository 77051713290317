import axios from "axios";
import { apiUrl, PORT } from "../../../environment";
import { loadingAction } from "../../../components/common/loader/LoaderSlice";
import { getInfo } from "../../auth/service/Auth.header";
import queryString from "query-string";

const clientId = process.env.REACT_APP_TRUSTAP_CLIENT_ID;
const trustap_complete_profile_url =
  process.env.REACT_APP_TRUSTAP_COMPLETE_PROFILE_URL;

export const userbyId = async (_id) => {
  let token = localStorage.getItem("user");
  let token1 = JSON.parse(token);
  if (token1?.Token) {
    let axiosConfig = {
      headers: {
        Authorization: `Bearer ${token1?.Token}`,
      },
    };
    return axios.get(`${apiUrl}${PORT}/users/getbyid?_id=${_id}`, axiosConfig);
  }
};

export const getStaffList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/customer/getAllStaffList?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response?.data);
          dispatch(loadingAction(false));
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) { }
};

// export const getByuserId = async (param, callback, dispatch) => {
//   try {
//     dispatch(loadingAction(true));
//     axios
//       .get(
//         `${apiUrl}${PORT}/customer/getstaffbyid?${queryString.stringify(param)}`
//       )
//       .then((response) => {
//         callback(response);
//         dispatch(loadingAction(false));
//       })
//       .catch((error) => {
//         dispatch(loadingAction(false));
//         callback(false);
//       });
//     // }
//   } catch (err) {}
// };



export const getByuserId = async (param, callback, dispatch) => {

  const { type } = param;

  try {
    dispatch(loadingAction(true));

    // Set the URL based on the user type
    const url =
      type === "staff"
        ? `${apiUrl}${PORT}/customer/getstaffbyid?${queryString.stringify(
          param
        )}`
        : `${apiUrl}${PORT}/customer/getrestrodetailbyid?${queryString.stringify(
          param
        )}`;

    // Fetch data from the server
    const response = await axios.get(url);


    // Call the callback function with the response
    callback(response);
  } catch (error) {
    console.error("Error fetching data:", error);
    callback(false); // Return false in case of error
  } finally {
    dispatch(loadingAction(false)); // Ensure loading action is stopped
  }
};

/**
 * 
*/

export const getStaffOnly = async (param, callback, dispatch) => {

  const { type } = param;

  try {
    dispatch(loadingAction(true));

    // Set the URL based on the user type
    const url =
      type === "staff"
        ? `${apiUrl}${PORT}/customer/getStaffOnly?${queryString.stringify(
          param
        )}`
        : `${apiUrl}${PORT}/customer/getrestrodetailbyid?${queryString.stringify(
          param
        )}`;

    // Fetch data from the server
    const response = await axios.get(url);
    // Call the callback function with the response
    callback(response);
  } catch (error) {
    console.error("Error fetching data:", error);
    callback(false); // Return false in case of error
  } finally {
    dispatch(loadingAction(false)); // Ensure loading action is stopped
  }
};

export const AddStafftipping = async (
  resto_id,
  customer_id,
  tip_amount,
  total_tip_amount,
  staff_id,
  donation_amount,
  service_charge,
  customer_comment,
  is_customer_commented,
  is_group_tip,
  customer_rating_to_staff,
  staff_tip_amount,
  guest_email,
  guest_name,
  guest_mobile,
  is_guest_registerd_user,
  is_guest_tip,
  gateway,
  paymentType,
  feesStatus,
  customerFullname,
  paymentAmount,
  totalAmount,
  email,
  full_name,
  customerEmailAdd,
  group_tip_status,
  dispatch
) => {
  try {
    dispatch(loadingAction(true));
    const response = await axios.post(`${apiUrl}${PORT}/tip/addtip`, {
      resto_id,
      customer_id,
      tip_amount,
      total_tip_amount,
      staff_id,
      donation_amount,
      service_charge,
      customer_comment,
      is_customer_commented,
      is_group_tip,
      customer_rating_to_staff,
      staff_tip_amount,
      guest_email,
      guest_name,
      guest_mobile,
      is_guest_registerd_user,
      is_guest_tip,
      gateway,
      paymentType,
      feesStatus,
      customerFullname,
      paymentAmount,
      totalAmount,
      email,
      full_name,
      customerEmailAdd,
    });
    if (response.data.status) {
      dispatch(loadingAction(false));
      return response;
    } else {
      dispatch(loadingAction(false));

      return response;
    }
  } catch (e) {
    return null;
  }
};

export const getTotalTippingHistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/tip/tiphistory?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
          dispatch(loadingAction(false));
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getGroupTippingHistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/customer/getgrouptipbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
          dispatch(loadingAction(false));
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getStaffTippingHistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/customer/getstafftipbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
          dispatch(loadingAction(false));
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const RestaurantList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/users/userlist?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
          dispatch(loadingAction(false));
        })
        .catch((error) => {
          callback(false);
          dispatch(loadingAction(false));
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getRestrodetailsByid = async (param, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/customer/getrestrodetailbyid?${queryString.stringify(
          param
        )}`
      )
      .then((response) => {
        callback(response);
        dispatch(loadingAction(false));
      })
      .catch((error) => {
        callback(false);
        dispatch(loadingAction(false));
      });
  } catch (err) {
    console.log(err);
  }
};

export const AllstaffList = async (param, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/allstafflist?${queryString.stringify(
          param
        )}`
      )
      .then((response) => {
        callback(response);
        dispatch(loadingAction(false));
      })
      .catch((error) => {
        callback(false);
        dispatch(loadingAction(false));
      });
  } catch (err) {
    console.log(err);
  }
};

export const AddGroupTip = async (
  resto_id,
  customer_id,
  customer_tip_amount,
  total_tip_amount,
  donation_amount,
  service_charge,
  customer_comment,
  is_customer_commented,
  tip_type,
  is_group_tip,
  group_tip_status,
  guest_email,
  guest_name,
  guest_mobile,
  is_guest_registerd_user,
  is_guest_tip,
  total_staff_given_amount,
  customer_rating_to_staff,
  dispatch
) => {
  try {
    dispatch(loadingAction(true));

    const response = await axios.post(`${apiUrl}${PORT}/tip/addtip`, {
      resto_id,
      customer_id,
      customer_tip_amount,
      total_tip_amount,
      donation_amount,
      service_charge,
      customer_comment,
      is_customer_commented,
      tip_type,
      is_group_tip,
      group_tip_status,
      guest_email,
      guest_name,
      guest_mobile,
      is_guest_registerd_user,
      is_guest_tip,
      total_staff_given_amount,
      customer_rating_to_staff,
    });
    if (response.data.status) {
      dispatch(loadingAction(false));

      return response;
    } else {
      dispatch(loadingAction(false));

      return response;
    }
  } catch (e) {
    return null;
  }
};

export const getTippingDetails = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .get(
          `${apiUrl}${PORT}/tip/getstaffTipDetailsById?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const searchData = async (param, callback, Key, dispatch) => {
  try {
    const response = axios
      .get(
        `${apiUrl}${PORT}/customer/searchAll?${queryString.stringify(
          param,
          Key
        )}`
      )

      .then((response) => {
        callback(response?.data);
      })
      .catch((error) => {
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};

export const getTotalnoofTippingHistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .get(
          `${apiUrl}${PORT}/tip/nooftiphistory?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getStaffnoofTippingHistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .get(
          `${apiUrl}${PORT}/customer/getnoofstafftipbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getgroupnoofTippingHistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .get(
          `${apiUrl}${PORT}/customer/getnoofgrouptipbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Resturant_Authenticate = async (
  sent_by_id,
  role,
  full_name,
  email,
  message,
  is_contact_support,
  complaint_restro_id
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      const response = await axios.post(
        `${apiUrl}${PORT}/landingpage/addcontactus`,
        {
          sent_by_id,
          role,
          full_name,
          email,
          message,
          is_contact_support,
          complaint_restro_id,
        },
        axiosConfig
      );

      return response;
    }
  } catch (e) {
    return null;
  }
};

//Api call for add card
export const addCardDetails = async (data, dispatch) => {
  const dateArr = data?.card_expYear.split("/");
  try {
    dispatch(loadingAction(true));

    const response = await axios.post(`${apiUrl}${PORT}/payment/addNewCard`, {
      _id: data?.customerId,
      card_Number: Number(data?.card_Number),
      card_ExpMonth: Number(dateArr[0]),
      card_ExpYear: Number(dateArr[1]),
      card_CVC: Number(data?.card_CVC),
      card_Name: data?.card_Name,
    });
    if (response.data.status) {
      dispatch(loadingAction(false));

      return response;
    } else {
      dispatch(loadingAction(false));

      return response;
    }
    // }
  } catch (e) {
    return null;
  }
};

//Api call for payment processing
export const makePayment = async (id, customerId, dispatch) => {
  try {
    dispatch(loadingAction(true));
    const response = await axios.post(`${apiUrl}${PORT}/payment/createCharge`, {
      tip_id: id,
      _id: customerId,
    });
    if (response.data.status) {
      dispatch(loadingAction(false));
      return response;
    } else {
      dispatch(loadingAction(false));
      return response;
    }
    // }
  } catch (e) {
    return null;
  }
};

//Add guest card details and payment api call
//Api call for add card
export const addGuestCardDetails = async (data, dispatch) => {
  const dateArr = data?.card_expYear.split("/");
  try {
    dispatch(loadingAction(true));
    const response = await axios.post(
      `${apiUrl}${PORT}/payment/addguestnewcard`,
      {
        _id: data?.guestId,
        card_Number: Number(data?.card_Number),
        card_ExpMonth: Number(dateArr[0]),
        card_ExpYear: Number(dateArr[1]),
        card_CVC: Number(data?.card_CVC),
        card_Name: data?.card_Name,
      }
    );
    if (response.data.status) {
      dispatch(loadingAction(false));
      return response;
    } else {
      dispatch(loadingAction(false));
      return response;
    }
    // }
  } catch (e) {
    console.log("e", e);
    return null;
  }
};

//Api call for payment processing
export const makeGuestPayment = async (tipid, id, dispatch) => {
  try {
    dispatch(loadingAction(true));
    const response = await axios.post(
      `${apiUrl}${PORT}/payment/createguestcharges`,
      {
        tip_id: tipid,
        _id: id,
      }
    );
    if (response.data.status) {
      dispatch(loadingAction(false));
      return response;
    } else {
      dispatch(loadingAction(false));
      return response;
    }
    // }
  } catch (e) {
    return null;
  }
};

export const checkPaypalPayment = async (values, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    const response = await axios.post(
      `${apiUrl}${PORT}/tip/checkPaypalPayment`,
      values
    );

    callback(response?.data);
    dispatch(loadingAction(false));

    // }
  } catch (e) {
    return null;
  }
};

export const successPaymentMess = async (paymentValues, dispatch) => {
  try {
    dispatch(loadingAction(true));

    const response = await axios.post(`${apiUrl}${PORT}/users/paymentSucess`, {
      paymentValues,
    });
    if (response.data.status) {
      dispatch(loadingAction(false));

      return response;
    } else {
      dispatch(loadingAction(false));

      return response;
    }
  } catch (e) {
    dispatch(loadingAction(false));

    return null;
  }
};

export const getCustomerFeedback = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .get(
          `${apiUrl}${PORT}/customer/getcustomerfeedback?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};
export const getCustomerDashboardData = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .get(
          `${apiUrl}${PORT}/customer/getCustomerDashboardData?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCustomerStatdData = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .get(
          `${apiUrl}${PORT}/customer/getCustomerStatData?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          callback(response);
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getStripePaypalCharge = async (callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    await axios
      .get(`${apiUrl}${PORT}/tip/getStripePaypalCharge`)
      .then((val) => {
        callback(val?.data);
        dispatch(loadingAction(false));
      })
      .catch((err) => {
        dispatch(loadingAction(false));
      });
  } catch (e) {
    return null;
  }
};

export const sendPaymentResponse = async (data) => {
  try {
    const response = await axios.put(
      `${apiUrl}${PORT}/tip/update-transaction`,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateTrustapStatus = async (data) => {
  try {
    const response = await axios.put(
      `${apiUrl}${PORT}/users/successTrustap`,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const completeTrustapProfile = async () => {
  try {
    const completeProfileUrl = `${trustap_complete_profile_url}${clientId}`;

    window.location.href = completeProfileUrl;
  } catch (err) {
    console.log("Error opening Trustap profile URL:", err);
  }
};


