import HomeHeader from "../../homePages/common/HomeHeader";
import SearchFilter from "../../homePages/common/SearchFilter";
import { RestaurantList } from "../../customerInterface/Service/Auth.Service";
import { useDispatch, } from "react-redux";
import React, { useEffect, useState } from "react";
import { API_BASEURL } from "../../../environment";
import { useNavigate } from "react-router-dom";
import {
  pagination,
} from "../../../components/common/utils/message";

function RestaurantSearchResults() {
  const [restrolist, setRestroList] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  useEffect(() => {
    _getRestaurantList();
  }, [dispatch]);

  const _getRestaurantList = () => {
    RestaurantList(
      { page, limit },
      (data) => {
        setRestroList(data?.data?.result?.docs);
      },
      dispatch
    );
  };

  const buttonviewHandlerpage = async (data) => {
    navigate(`/customer/restaurantprofilescreen/${data?._id}`);
  };
  return (
    <div className="searchList">
      <HomeHeader />
      <section className="searchListContent">
        <div className="container">
          <SearchFilter />
          <div className="row mt-4">
            <div className="col direction">
              <p class="mb-0">
                Home <i class="fa-solid fa-angles-right"></i>
              </p>
            </div>
          </div>
          <div className="row mb-5">
            {restrolist &&
              restrolist?.map((data, index) => {
                let ProfilePath = `${API_BASEURL}/public/posts/${data?.image}`;
                return (
                  <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                    <div className="searchContent">
                      <img src={ProfilePath} alt="" />
                      <div className="text">
                        <h4>{data?.restaurant_name}</h4>
                        <p>
                          <i className="fa-solid fa-location-dot"></i>{" "}
                          {/* {data?.addressLine1}, */}
                          <span> {data?.city}</span>
                        </p>
                      </div>
                      <div
                        className="nextBtn"
                        onClick={(e) => buttonviewHandlerpage(data)}
                      >
                        <i className="fa-solid fa-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row mb-5">
            <div className="col">
              <nav aria-label="Page navigation example" class="paginationRow">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="/" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="/">
                      1
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="/">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a className="page-link" href="/">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a className="page-link" href="/" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RestaurantSearchResults;
