import HomeHeader from "../../homePages/common/HomeHeader";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import {
  getTipDetails,
  AllstaffLists,
  UpdateGroupTip,
} from "../../RestoInterface/Service/Auth.service";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SocketContext } from "../../../socket/SocketContext";

function TipManagementDistributed() {
  const TipId = useParams();
  const [tipDetail, setTipDetail] = useState();
  const [staffList, setStaffList] = useState();
  const [toggle, setToggle] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const userdata = useSelector((state) => state);
  const socket = useContext(SocketContext);

  let user_id = userdata?.login?.data?.result._id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [dataSelectAll, setDataSelectAll] = useState(false);
  const [dataObjItem, setDataObjItem] = useState([]);
  const [groupStaff, setGroupStaff] = useState([]);

  const [input, set_input] = useState({
    staff_comment: "",
    group_tip_status: 2,
  });

  useEffect(() => {
    _getTippingDetails();
    _getNoofStaffListing();
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    set_input((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
  };
  const _getTippingDetails = () => {
    getTipDetails(
      { _id: TipId.id },
      (data) => {
        setTipDetail(data?.data?.result);
      },
      dispatch
    );
  };

  const _getNoofStaffListing = () => {
    AllstaffLists(
      { resto_id: user_id },
      (data) => {
        setStaffList(data?.data?.result);
      },
      dispatch
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(staffList);
      setDataSelectAll(true);

      let staffAmount = tipDetail.total_staff_given_amount / staffList.length;
      setGroupStaff([]);
      for (let i = 0; i < staffList.length; i++) {
        let obj = {
          group_staff_id: staffList[i].staff_id,
          group_staff_amount: staffAmount,
          staff_name: staffList[i].staff_name,
        };
        groupStaff.push(obj);
      }

      setDataObjItem(groupStaff);
    } else {
      setSelectedItems([]);
      setDataObjItem([]);
    }
  };

  let array = [];
  selectedItems.forEach((staff) => {
    let obj = {
      staff_id: staff?.staff_id,
      staff_name: staff?.userDetails?.full_name,
      profile: staff?.userDetails?.profile,
    };
    array.push(obj);
  });

  const updateFieldChanged = debounce((index, e, selectedItems) => {
    e.preventDefault(e);
    let newArr = [...selectedPrice];
    newArr[index] = e.target.value;
    const tarVal = e.target.value;
    let targetData = {
      group_staff_id: selectedItems[selectedItems.length - 1].staff_id,
      group_staff_amount: tarVal,
      staff_name: selectedItems[selectedItems.length - 1].staff_name,
      profile: selectedItems[selectedItems.length - 1].userDetails?.profile,
    };
    setDataObjItem([...dataObjItem, targetData]);

    setSelectedPrice(newArr);
  }, 1000);

  let _id = tipDetail?._id;
  const buttonHandler = async (data) => {
    let amountCal = dataObjItem.reduce(
      (accumulator, current) => accumulator + current.group_staff_amount,
      0
    );

    // console.log("safsdf", tipDetail?.total_staff_given_amount, amountCal);
    if (tipDetail?.total_staff_given_amount < Math.round(amountCal)) {
      toast.error(
        "Distributed Tip can't be more than Total Amount!",
        {
          theme: "dark",

          position: toast.POSITION.BOTTOM_RIGHT,
        },
        { autoClose: 1000 }
      );
      return;
    }

    const res = await UpdateGroupTip(
      _id,
      dataObjItem,
      input.staff_comment,
      input.group_tip_status,
      user_id
    );
    if (res?.data.status == true) {
      let reqData = {
        dataObjItem,
        senderId: user_id,
        type: "GroupTipDistribute",
      };
      socket.emit("distribute-group-tip", reqData);

      toast.success(
        "GroupTip sent successfully",
        {
          theme: "dark",

          position: toast.POSITION.BOTTOM_RIGHT,
        },
        { autoClose: 1000 }
      );
      navigate(`/resto/tiprequestsent/${data?._id}`);
    }
  };
  const equalTip = (
    tipDetail?.total_staff_given_amount / selectedItems.length
  ).toFixed(2);
  const query = document.querySelectorAll(".max");
  for (let data of query) {
    data.value = equalTip;
  }

  const navigatetoback = () => {
    navigate("/resto/tipmanagement");
  };

  const handleChange12 = (e, index) => {
    // console.log("dataObjItem", dataObjItem, index);
    dataObjItem[index].group_staff_amount = parseInt(e.target.value);

    // console.log("xsfgdfg", e.target.value, index);
  };

  const handleSelectionChange = (event, index) => {
    let data = staffList;
    setStaffList([]);

    if (event.target.checked) {
      const newData = [...selectedItems, staffList[index]];
      setSelectedItems(newData);

      let targetData = {
        group_staff_id: staffList[index].staff_id,
        group_staff_amount: 0,
        staff_name: staffList[index].staff_name,
        profile: staffList[index].userDetails?.profile,
      };
      const newData1 = [...dataObjItem, targetData];
      setDataObjItem(newData1);
    } else {
      const newArray1 = selectedItems.filter(
        (item) => item.staff_id !== staffList[index].staff_id
      );
      setSelectedItems(newArray1);
      const newArray = dataObjItem.filter(
        (item) => item.group_staff_id !== staffList[index].staff_id
      );
      setDataObjItem(newArray);
    }
    setStaffList(data);
  };

  return (
    <div className="TipManagement2">
      <HomeHeader />

      <div className="container">
        <div className="row my-4">
          <div className="col direction">
            <p class="mb-0">
              <a onClick={navigatetoback}>Tip Management</a>{" "}
              <i class="fa-solid fa-angles-right"></i>
              <span>{tipDetail?.CustomerDetails?.full_name}</span>
            </p>
          </div>
        </div>
        <div className="row requestToTip">
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-6">
                <div className="heading fw-bold">Tip Amount ($) :</div>
              </div>
              <div className="col-md-6 col-6">
                <div className="data">
                  {tipDetail?.total_staff_given_amount}
                </div>
              </div>
              {/* <div className="col-md-3 col-12">
                <div className="text">
                  <p className="mb-0">
                    Requested to GoTipMe Admin <i class="fa-solid fa-check"></i>
                  </p>
                </div>
              </div> */}
            </div>
            <hr />
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-4"></div>
              <div className="col-md-6 col-8">
                <div className="data"></div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-md-3 col-4">
                <div className="heading fw-bold">Feedback:</div>
              </div>
              <div className="col-md-6 col-8">
                <div className="data">
                  {console.log(
                    "tipDetail?.customer_comment",
                    tipDetail?.customer_comment
                  )}
                  <p className="mb-0">
                    {tipDetail?.customer_comment === ""
                      ? "-"
                      : tipDetail?.customer_comment}
                  </p>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-6"></div>
              <div className="col-md-6 col-6">
                <div className="data"></div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-md-3 alc dflex">
                <div className="heading fw-bold whiteText">Reply to Simon:</div>
              </div>
              <div className="col-md-9 mt-2 mt-md-0">
                <div className="data">
                  <input
                    type="text"
                    className="form-control"
                    name="staff_comment"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 " style={{ gap: "10px" }}>
          <div className="col-3 ">
            <div className="containerVal">
              <div class="form-check m-2 text-start">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={handleSelectAll}
                  checked={staffList === selectedItems ? true : false}
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Equally Distribute
                </label>
                <br />
                Total amount :sdfdfsdsg{tipDetail?.total_staff_given_amount}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          {staffList &&
            staffList?.map((data, index) => (
              <div className="col-3 ">
                <div className="containerVal" key={index}>
                  <div class="form-check m-2 text-start">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={selectedItems.includes(data)}
                      onChange={(event) => {
                        handleSelectionChange(event, index);
                      }}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      {data?.userDetails?.full_name}
                    </label>
                    <br />
                  </div>
                  {selectedItems.map((val, index) => (
                    <>
                      {val.staff_id === data.staff_id && (
                        <div className="inputVal ">
                          <input
                            type="text"
                            className="form-control max"
                            name="group_staff_amount"
                            defaultValue={equalTip}
                            onChange={(e) => handleChange12(e, index)}
                          />
                        </div>
                      )}
                    </>
                  ))}
                  {!selectedItems.some(
                    (val) => val.staff_id === data.staff_id
                  ) && (
                    <div className="inputVal ">
                      <input
                        type="text"
                        className="form-control"
                        name="group_staff_amount"
                        disabled
                        defaultValue={0}
                        onChange={(e) => handleChange12(e, index)}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>

        <div className="container">
          <div className="row">
            <div className="col">
              <div className="buttonsRow">
                <div className="navBtn"></div>
                <div className="btnGroups">
                  <button
                    className="btn btn-main btn-height"
                    onClick={(e) => buttonHandler(tipDetail)}
                    data-bs-target="#exampleModal"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body ">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
              </div>
              <h3>Send Request</h3>
              <p>Select below option and define price for Pub star</p>
              <div className="row">
                <div className="col-12 mt-4">
                  <div className="staffBox">
                    <div className="selectItems ">
                      <div className="text-start">
                        <p className="mb-0">Select Pub Star</p>
                      </div>
                      <div className="selectBox">
                        <p className="mb-0">Select Pub Star</p>
                        <i
                          class="fa-solid fa-caret-down"
                          onClick={() => setToggle(!toggle)}
                        ></i>
                      </div>
                      {toggle && (
                        <div className="selectContent ">
                          <div class="form-check m-2 text-start">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={handleSelectAll}
                              checked={
                                staffList === selectedItems ? true : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Equally Distribute
                            </label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total amount :
                            {tipDetail?.total_staff_given_amount}
                          </div>
                          <div className="listBox">
                            {staffList &&
                              staffList?.map((data, index) => {
                                return (
                                  <div key={index} className="contentList">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        checked={selectedItems.includes(data)}
                                        onChange={(event) => {
                                          if (event.target.checked) {
                                            setSelectedItems([
                                              ...selectedItems,
                                              data,
                                            ]);

                                            setIsChecked(true);
                                          } else {
                                            setSelectedItems(
                                              selectedItems.filter(
                                                (selectedItem) =>
                                                  selectedItem !== data
                                              )
                                            );
                                            setIsChecked(false);
                                          }
                                        }}
                                      />
                                      <label
                                        class="form-check-label"
                                        for="flexCheckDefault"
                                      >
                                        {data?.userDetails?.full_name}
                                      </label>
                                      <p className="mb-0 text-start">Staff</p>

                                      {selectedItems.length != 0 ? (
                                        <div className="input">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="group_staff_amount"
                                            defaultValue={
                                              selectedItems.length != 0 &&
                                              staffList === selectedItems
                                                ? (
                                                    tipDetail?.total_staff_given_amount /
                                                    selectedItems.length
                                                  ).toFixed(2)
                                                : 0
                                            }
                                            onChange={(e) => {
                                              updateFieldChanged(
                                                index,
                                                e,
                                                selectedItems
                                              );
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        "-"
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <button
                            className="btn btn-main mt-4 btn-height"
                            // onClick={buttonHandler}
                            onClick={(e) => buttonHandler(tipDetail)}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Send Request
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <button className="btn btn-main mt-4" onClick={buttonHandler}>Send Request</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TipManagementDistributed;
