import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addCustomer, resendOtp, verifyOtp } from "./service/authService";
import gotip from "../../assets/images/gotipLogo.png";
import { Modal } from "react-bootstrap";
import { userlogin } from "./loginSlice";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { customToastUpdate } from "../../services/project";
// Testing here check code
function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModel, setShowModel] = useState(false);

  const [error, setErrors] = useState({});
  const [input, setInput] = useState({
    full_name: "",
    contact_number: "",
    email: "",
    password: "",
    social_media: "",
    confirmPassword: "",
    countryCode: "",
    user_name: "",
  });
  const [role, setRole] = useState();
  const [confirm, serConfirm] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [createPasswordType, setCreatePasswordType] = useState("password");

  // console.log("5sdfdsf" , sessionStorage.getItem("ROUTE_TYPE"), role)
  useEffect(() => {
    if (sessionStorage.getItem("ROUTE_TYPE") === "Pubstar") {
      setRole(3);
    } else {
      setRole(1);
    }
  }, []);

  /*--------@ For handle change for register form----------- */
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...error }; // Create a copy of errors
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "confirmPassword") {
      if (value === "") {
        serConfirm({
          confirmPassword: "Confirm password is required",
        });
      } else if (input.password === value) {
        serConfirm({
          matched: "Password matched ",
        });
      } else {
        serConfirm({
          confirmPassword: "Password not matched ",
        });
      }
    }

    // Clear the error message for the field if the value is valid
    if (newErrors[name]) {
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  /*--------@ For register the staff,customer----------- */
  const handelSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm(); // Renamed errors to newErrors
    if (
      Object.keys(newErrors).length === 0 &&
      input.password === input.confirmPassword
    ) {
      setErrors(newErrors);
      let reqData = {
        full_name: input.full_name,
        role: role,
        contact_number: input.contact_number,
        social_media: input.social_media,
        email: input.email,
        password: input.password,
        user_name: input.user_name,
        countryCode: input.countryCode,
      };
      const res = await addCustomer(reqData, dispatch);
      if (res?.data?.status == "Success") {
        setShowModel(true);
      } else {
        toast.error(
          "Email already registered",
          {
            theme: "dark",
          },
          {
            position: toast.POSITION.TOP_CENTER,
          },
          { autoClose: 1000 }
        );
      }
    } else {
      setErrors(newErrors);

      if (input.password !== input.confirmPassword) {
        serConfirm({ confirmPassword: "Password does not match" });
      }
    }
  };
  const submitOtp = async (e) => {
    e.preventDefault();
    if (!input.otp) {
      return toast.error(
        "OTP can't be Blank",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    } else if (input.otp.length !== 6) {
      return toast.error(
        "OTP should be 6 Digit",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }
    const res = await verifyOtp(
      { email: input.email, otp: input.otp },
      dispatch
    );
    // if (res?.status == 200) {
    //   // toast.success(
    //   //   "Registered successfully",
    //   //   {
    //   //     theme: "dark",
    //   //   },
    //   //   {
    //   //     position: toast.POSITION.TOP_CENTER,
    //   //   },
    //   //   { autoClose: 1000 }
    //   // );

    //   customToastUpdate(dispatch)
    //   // setTimeout(() => {
    //   navigate("/login");
    //   // }, 1000);
    if (res?.status == 200) {
      //  Login for customer
      if (res?.data?.role == 1) {
        dispatch(userlogin(res?.data));
        toast.success(
          "Login Successfully",
          {
            theme: "dark",
            className: "custom-toast",
            autoClose: 1000,
            position: toast.POSITION.BOTTOM_RIGHT,
          }
          // {
          //   position: "bottom-right",
          //   // ToastPosition:'bottom-right'
          // },
          // {  }
        );
        customToastUpdate(dispatch);
        navigate("/customer/dashboard");
      }
      //  Login for pub

      if (res?.data?.role == 2) {
        dispatch(userlogin(res?.data));
        toast.success("Registered Successfully", {
          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        customToastUpdate(dispatch);
        if (res?.data?.result?.is_updated == false) {
          navigate("/resto/dashboard");
        }
        if (res?.data?.result?.is_updated == true) {
          navigate("/resto/mainrestodashboard");
        }
      }
      //  Login for staff

      if (res?.data?.role == 3) {
        dispatch(userlogin(res?.data));
        toast.success("Login Successfully", {
          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        customToastUpdate(dispatch);
        navigate("/staff/dashboard");
      }

      // customToastUpdate(dispatch);
      // setTimeout(() => {
      // navigate("/login");
      // }, 1000);
    } else if (res == null) {
      toast.error(
        "OTP incorrect ",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }
  };

  const resentOtp = async (e) => {
    e.preventDefault();
    const res = await resendOtp({ email: input.email }, dispatch);
    if (res?.data?.status == "Success") {
      toast.success("OTP sent Successfully", {
        theme: "dark",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    } else {
      toast.error(
        "Something Went Wrong",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }
  };

  /*--------@ For register form validations----------- */
  const validateForm = () => {
    const errors = {};
    if (!input.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(input.email)) {
      errors.email = "Email is invalid";
    }

    if (!input.password) {
      errors.password = "Password is required";
    } else if (input.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (!input.full_name) {
      errors.full_name = "Full name is required";
    }

    // if (!input.contact_number) {
    //   errors.contact_number = "Contact number is required";

    // } else
    // if (
    //   !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
    //     input.contact_number
    //   )
    // ) {
    //   errors.contact_number = "Contact Number is invalid";
    // }  else if (
    //   !isValidPhoneNumber(input.contact_number)
    //   // input.contact_number.length < 12 ||
    //   // input.contact_number.length > 13
    // ) {
    //   // console.log(" input.contact_number.length", input.contact_number.length)
    //   errors.contact_number = "Contact number is invalid";
    // }

    return errors;
  };

  const handeblur = () => {};

  function onChangeRole(event) {
    setRole(event.target.value);
  }
  function handleInnerClick(event) {
    console.log("nextt");
    event.stopPropagation();
  }
  const handleChange1 = (value, name) => {
    let newErrors = { ...error };
    setInput({
      ...input,
      [name]: value,
    });
    if (newErrors[name]) {
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  return (
    <div className="authPage" style={{ cursor: "pointer" }}>
      <div className="container shadow">
        <div className="row m-0">
          <div className="col-md-6 dflex alc">
            <img
              src={gotip}
              alt=""
              className="authImage signUpImage"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>

          <div
            className="col-md-6 mt-5 mt-md-0"
            style={{
              background: "#f4f4f4",
              borderRadius: "15px",
              cursor: "auto",
            }}
          >
            <div className="content">
              <h3 className="text-center mt-3">
                Register{" "}
                <span> as a {`${role == 3 ? "Bartender" : "Pubgeor"} `} </span>
              </h3>

              <form className="form ">
                <div className="row">
                  <div className="col">
                    <div className="radioBox">
                      {/* <div
                        className="form-check form-check-inline"
                        onChange={onChangeRole}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="role"
                          value={3}
                          checked={role == 3}
                        />
                        <label className="form-check-label" for="inlineRadio1">
                          Pubstar
                        </label>
                      </div> */}
                      {/* <div
                        className="form-check form-check-inline"
                        onChange={onChangeRole}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="role"
                          value={1}
                          // checked={role === 1}
                        />
                        <label className="form-check-label" for="inlineRadio2">
                          Customer
                        </label>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="inputField">
                      <label htmlFor="fullName">Name</label>
                      <div className="inputBox blackBorder">
                        <input
                          type="text"
                          name="full_name"
                          className="form-control"
                          id="fullName"
                          autoFocus={true}
                          placeholder="Name"
                          onChange={handleChange}
                          onBlur={(e) => handeblur()}
                        />

                        <i className="fa-solid fa-user"></i>
                      </div>
                      {error.full_name && (
                        <span className="text-danger">{error.full_name}</span>
                      )}
                    </div>
                    {role == 3 ? (
                      <div className="inputField">
                        <label htmlFor="fullName">User Name</label>
                        <div className="inputBox blackBorder">
                          <input
                            type="text"
                            name="user_name"
                            className="form-control"
                            id="user_name"
                            placeholder="User Name"
                            onChange={handleChange}
                            // onBlur={(e) => handeblur()}
                          />

                          <i className="fa-solid fa-user"></i>
                        </div>
                        {/* {error.full_name && (
                        <span className="text-danger">{error.full_name}</span>
                      )} */}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="inputField">
                      <label htmlFor="email">Your Email</label>
                      <div className="inputBox blackBorder">
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                          onChange={handleChange}
                          required
                          onBlur={(e) => handeblur()}
                        />

                        <i className="fa-solid fa-envelope"></i>
                      </div>
                      {/* {!valid.email && <span className="text-danger">{valid.emailError}</span>} */}
                      {error.email && (
                        <span className="text-danger">{error.email}</span>
                      )}
                    </div>
                    <div className="inputField1 ">
                      <label htmlFor="contact">Pub Contact #</label>
                      <div className="inputBox  blackBorder">
                        <PhoneInput
                          //  fieldname="restaurantContact_number"
                          className="form-control px-3"
                          name="contact_number"
                          placeholder="Pub Contact #"
                          international
                          // maxLength={15}
                          defaultCountry="GB"
                          onChange={(e) => handleChange1(e, "contact_number")}
                        />
                      </div>
                      {error.contact_number && (
                        <span className="text-danger">
                          {error.contact_number}
                        </span>
                      )}
                      {error.countryCode && (
                        <span className="text-danger">{error.countryCode}</span>
                      )}
                    </div>

                    {/* <div className="inputField">
                      <label htmlFor="contact">Contact No</label>
                      <div className="inputBox countryCodeBox blackBorder">
                      <text className="countryCode1" >+</text>
                        <input
                          type="text"
                          name="countryCode"
                          className="form-control px-3 countryCode countryCode2"
                          id="country"
                          pattern="/^\+(\d{1}\-)?(\d{1,3})$/"
                          maxLength={4}
                           onChange={handleChange}
                          required
                        />
                        <input
                          type="text"
                          name="contact_number"
                          className="form-control px-3"
                          id="contact"
                          placeholder="Contact Number"
                          onChange={handleChange}
                          required
                          onBlur={(e) => handeblur()}
                          maxLength="13"
                        />
                      </div>
                      {error.contact_number && (
                        <span className="text-danger">
                          {error.contact_number}
                        </span>
                      )}
                      {error.countryCode && (
                        <span className="text-danger">
                          {error.countryCode}
                        </span>
                      )}
                    </div> */}

                    {/* <div className="inputField">
                      <label htmlFor="socialMedia">
                        Social Media (Optional)
                      </label>
                      <div className="inputBox blackBorder">
                        <input
                          type="text"
                          name="social_media"
                          className="form-control px-3"
                          id="socialMedia"
                          placeholder="Enter one of your social media Eg. Instagram"
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div> */}
                    <div className="inputField">
                      <label htmlFor="createPassword">Create Password</label>
                      <div className="inputBox blackBorder">
                        <input
                          type={createPasswordType ? "password" : "text"}
                          name="password"
                          className="form-control"
                          id="createPassword"
                          placeholder="Create Password"
                          onChange={handleChange}
                          required
                          eyeIcon={true}
                        />

                        <i className="fa-solid fa-lock"></i>
                        <i
                          onClick={() =>
                            setCreatePasswordType(!createPasswordType)
                          }
                          className={
                            createPasswordType
                              ? "fa-solid fa-eye seen"
                              : "fa-solid fa fa-eye-slash seen"
                          }
                        ></i>
                      </div>
                      {error.password && (
                        <span className="text-danger">{error.password}</span>
                      )}
                    </div>
                    <div className="inputField ">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <div className="inputBox blackBorder">
                        <input
                          type={passwordType ? "password" : "text"}
                          name="confirmPassword"
                          className="form-control"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          onChange={handleChange}
                        />

                        <i className="fa-solid fa-lock"></i>
                        <i
                          onClick={() => setPasswordType(!passwordType)}
                          className={
                            passwordType
                              ? "fa-solid fa-eye seen"
                              : "fa-solid fa fa-eye-slash seen"
                          }
                        ></i>
                      </div>
                      {confirm.confirmPassword && (
                        <span className="text-danger">
                          {confirm.confirmPassword}
                        </span>
                      )}
                      {confirm.matched && (
                        <span className="text-success">{confirm.matched}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    <button
                      className="btn btn-main w-100 btn-height"
                      type="submit"
                      onClick={handelSubmit}
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </form>
              <hr />

              <div>
                <p className="text-center mt-4">
                  Already Registered?
                  {/* <a href="/">Login</a> */}
                  <Link to="/login"> Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModel}
        onClick={handleInnerClick}
        onHide={() => setShowModel(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className="modal-heading"> */}
            <h3>Verification</h3>
            {/* </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body module">
            <p>We have sent you a code to verify your email.</p>
            <h4>Enter Your OTP Here</h4>
            <div className="otpBox">
              <input
                type="text"
                className="form-control blackBorder"
                name="otp"
                onChange={handleChange}
                maxLength={6}
              />
            </div>
            <div className="dflex jcc" style={{ gap: "10px" }}>
              <button className="btn btn-main btn-height" onClick={resentOtp}>
                Resend
              </button>
              <button className="btn btn-main btn-height" onClick={submitOtp}>
                Continue
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Register;
