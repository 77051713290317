import HomeHeader from "../../homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RestroDetailsbyId, LeaveRequest } from "../service/Auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { Resturant_Authenticate } from "../../customerInterface/Service/Auth.Service";
import { API_BASEURL, Frontend_Url } from "../../../environment";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function RestaurantProfileScreen() {
  const [restroData, setRestroData] = useState();
  const [cancelledrestroresquest, setCancelledrestroRequest] = useState();
  const navigate = useNavigate();
  const [error, setErrors] = useState({});

  const dispatch = useDispatch();

  const retroId = useParams();
  const userdata = useSelector((state) => state);
  //let user_id = userdata?.login?.data?.result?._id;

  let loginRole = userdata?.login?.data?.role;
  let user_id;
  if (loginRole == 3) {
    user_id = userdata?.login?.data?.result?._id;
  }
  if (loginRole == 0) {
    user_id = userdata?.adminstaff?.data?._id;
  }

  const [input, setInput] = useState({
    _id: restroData?._id,
    status: 4,
  });
  const [formdata, setFormdata] = useState({
    sent_by_id: userdata?.login?.data?.result?._id,
    role: userdata?.login?.data?.result?.role,
    full_name: userdata?.login?.data?.result?.full_name,
    email: userdata?.login?.data?.result?.email,
    message: "",
    is_contact_support: 0,
    complaint_restro_id: retroId.id,
  });
  const handleformChange = (e) => {
    const { name, value } = e.target;
    setFormdata((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };
  const RestroAddAuthenticate = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      const res = await Resturant_Authenticate(
        formdata.sent_by_id,
        formdata.role,
        formdata.full_name,
        formdata.email,
        formdata.message,
        formdata.is_contact_support,
        formdata.complaint_restro_id
      );

      if (res.data.status == true) {
        toast.success(
          "Submit successfully",
          {
            theme: "dark",
         
            position: toast.POSITION.BOTTOM_RIGHT,
          },
          { autoClose: 1000 }
        );
      }
    }
  };
  const validateForm = () => {
    const errors = {};

    if (!formdata.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handeblur = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };
  useEffect(() => {
    getByrestroId();
  }, [dispatch]);

  const getByrestroId = () => {
    RestroDetailsbyId(
      { resto_id: retroId.id, staff_id: user_id },
      (data) => {
        setRestroData(data[0]);
      },
      dispatch
    );
  };

  let path = `${API_BASEURL}/public/posts/${restroData?.restroDetails?.image}`;

  const leaveButtonhandler = async () => {
    const res = await LeaveRequest(restroData?._id, input.status);
    if (res.data.status == true) {
      toast.warn(
        "Leave Request accepted.",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
      navigate("/staff/mypreviousjob");
    }
  };

  const buttonmanagestaffHandlerpage = async (data) => {
    navigate(`/staff/managestaff/${data?.resto_id}`);
  };
  const buttonInvitationrequestHandlerpage = async (data) => {
    navigate(`/staff/invitationrequest/${data?.resto_id}`);
  };
  const buttonGroupDistributedHandlerpage = async (data) => {
    navigate(`/staff/grouptipdistribute/${data?.resto_id}`);
  };

  const menuUrl = `${Frontend_Url}/customer/stafftipping/${user_id}`; // Replace with your restaurant's menu URL

  const downloadQR = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "123456.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const mapStyles = {
    height: "25vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: restroData?.restroDetails?.lat || 51.901516,
    lng: restroData?.restroDetails?.lng || -8.468283,
  };

  const locations = [
    {
      name: "Cask Pub and Kitchen",
      location: {
        lat: restroData?.restroDetails?.lat || 51.901516,
        lng: restroData?.restroDetails?.lng || -8.468283,
      },
    },

    ,
  ];
  return (
    <div className="RIAddAddress">
      <HomeHeader />

      <div className="container">
        <div className="row my-4">
          <div className="col direction">
            <p class="mb-0" >
              <span className="cursorPointer" onClick={()=>{navigate("/staff/StaffHome")}}>Home</span> <i class="fa-solid fa-angles-right"></i>
              <span>
                {restroData?.restroDetails?.restaurant_name},{" "}
                {/* {restroData?.restroDetails?.addressLine1},{" "}
                {restroData?.restroDetails?.city} (
                {restroData?.restroDetails?.postcode}) */}
              </span>
            </p>
            {/* <button className="btn btn-main-outline">Generate QR Code</button> */}
          </div>
          <div className="col-sm-6 text-end">
            <button
              className="btn btn-main-outline btn-height"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              Generate QR Code
            </button>
          </div>
        </div>
        <div className="row ProfileDesign">
          <div className="col-lg-4">
            <img src={path} alt="" className="w-100 mainImage" />
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <h1>{restroData?.restroDetails?.restaurant_name}</h1>
            <p>
              <i class="fa-solid fa-envelope"></i>
              &nbsp;{restroData?.restroDetails?.email}
            </p>
            <p>
              <i class="fa-solid fa-phone"></i> 
              {restroData?.restroDetails?.restaurantContact_number?.substr(0, 3) + "  " + restroData?.restroDetails?.restaurantContact_number?.substr(3)}
              {/* {restroData?.restroDetails?.restaurantContact_number} */}
            </p>
            <div
              className="col-lg-8"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
            >
              <p>Are the pub details incorrect?</p>
            </div>
            <div className="review">
            </div>
          </div>
          <div className="col-lg-4 text-end mt-4 mt-lg-0">
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            >
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={defaultCenter}
              >
                {locations.map((item) => {
                  return <Marker key={item.name} position={item.location} />;
                })}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>

        <div className="row statusDetails my-5">
          <div className="col-md-6">
            <div className="row">
              <div className="col-4 fw-bold">Status:</div>
              <div className="col-4 text-secondary blackText">{restroData?.restroDetails?.status ? "Active" : "In Active"}</div>
              <div className="col-4"></div>
            </div>
            <div className="row">
              <div className="col-4 fw-bold">Your Role :</div>
              <div className="col-4 text-secondary blackText">Pubstar</div>
              <div className="col-4"></div>
            </div>
            <div className="row">
              <ul>
                <div className="col-sm-3 fw-bold">Permissions :</div>
                {restroData?.manage_staff_delete == true ||
                restroData?.manage_staff_edit == true ? (
                  <div
                    className="col-sm-3 col-6 fw-regular"
                    onClick={(e) => buttonmanagestaffHandlerpage(restroData)}
                  >
                    {" "}
                    <li>
                      <ul>Manage Pubstar </ul>{" "}
                    </li>
                  </div>
                ) : (
                  ""
                )}
                {restroData?.invitation_request == true ? (
                  <div
                    className="col-sm-3 col-6 fw-regular"
                    onClick={(e) =>
                      buttonInvitationrequestHandlerpage(restroData)
                    }
                  >
                    <li>
                      <ul>Invitation Request</ul>
                    </li>
                  </div>
                ) : (
                  ""
                )}
                {restroData?.tip_management == true ? (
                  <div
                    className="col-sm-3 col-6 fw-regular"
                    onClick={(e) =>
                      buttonGroupDistributedHandlerpage(restroData)
                    }
                  >
                    <li>
                      <ul>Group Tip</ul>
                    </li>
                  </div>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">
              <div className="buttonsRow">
                <div className="navBtn"></div>
                <div className="btnGroups">
                  <button
                    className="btn btn-main btn-height"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Leave Request
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body ">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
              </div>
              <h3>Leave Request</h3>
              <div className="otpBox">
                <p>Are you want to leave the request</p>
              </div>
              <button
                className="btn btn-main btn-height "
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={leaveButtonhandler}
              >
                Yes
              </button>
              &nbsp;
              <button
                className="btn btn-white-outline btn-height"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body ">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
              </div>
              <div className="otpBox">
                {/* <p>Are you want to reject this invitation</p> */}
              </div>
              <QRCode
                id="123456"
                value={menuUrl}
                level={"H"}
                includeMargin={true}
                size={256}
              />
              <br />
              <br />
              <button
                className="btn btn-main"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
              &nbsp;&nbsp;
              <button
                className="btn btn-main"
                value="Download"
                onClick={downloadQR}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
              </div>
              <form onSubmit={(e) => RestroAddAuthenticate(e)}>
                <div className="row">
                  <div className="col-10 text-start m-auto">
                    {/* <label htmlFor="writeConcern">Write your concern</label> */}
                    <textarea
                      type="text"
                      name="message"
                      id="writeConcern"
                      cols="30"
                      rows="5"
                      placeholder="Enter Message"
                      onChange={handleformChange}
                      className="form-control my-2"
                      onBlur={(e) => handeblur()}
                    ></textarea>
                  </div>
                  {error.message && (
                    <span className="text-danger">{error.message}</span>
                  )}
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <button
                      className="btn btn-main"
                      data-bs-dismiss="modal"
                      type="submit"
                      onClick={RestroAddAuthenticate}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantProfileScreen;
