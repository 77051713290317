import HomeHeader from "../../homePages/common/HomeHeader";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch,  } from "react-redux";
import { API_BASEURL } from "../../../environment";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getMyPreviousJobProfile, rejoinRequest } from "../service/Auth.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function MyPreviousJobProfile() {
  const retroId = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [previousProfileJobData, setPreviousProfileJobData] = useState();

  useEffect(() => {
    getPreviouJobProfile();
  }, [dispatch]);

  const getPreviouJobProfile = () => {
    getMyPreviousJobProfile(
      { _id: retroId.id },
      (data) => {
        setPreviousProfileJobData(data?.data?.result);
      },
      dispatch
    );
  };
  const rejoinButtonhandler = async () => {
    const res = await rejoinRequest(previousProfileJobData._id);
    if (res?.data?.status == true) {
      toast.warn(
        "Rejoin request succefully.",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
      navigate("/staff/mypreviousjob");
    }
  };
  let ProfilePath = `${API_BASEURL}/public/posts/${previousProfileJobData?.restroDetails?.image}`;
  return (
    <div className="RIAddAddress">
      <HomeHeader />

      <div className="container">
        <div className="row my-4">
          <div className="col direction">
            <p class="mb-0">
             <a onClick={()=>navigate('/staff/mypreviousjob')}>My Previous Jobs</a>  <i class="fa-solid fa-angles-right"></i>
              <span>
                {previousProfileJobData?.restroDetails?.restaurant_name}
                {/* ,{" "} */}
                {/* {previousProfileJobData?.restroDetails?.addressLine1},{" "}
                {previousProfileJobData?.restroDetails?.city} (
                {previousProfileJobData?.restroDetails?.postcode}) */}
              </span>
            </p>
            {/* <button className="btn btn-main-outline">Generate QR Code</button> */}
          </div>
        </div>
        <div className="row ProfileDesign">
          <div className="col-lg-4">
            <img
              src={ProfilePath}
              alt=""
              className="w-100 mainImage"
            />
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <h1>{previousProfileJobData?.restroDetails?.restaurant_name}</h1>
            <p>
              {/* <i className="fa-solid fa-location-dot backText"></i> {previousProfileJobData?.restroDetails?.addressLine1}, */}
              <span> {previousProfileJobData?.restroDetails?.city} ({previousProfileJobData?.restroDetails?.postcode})</span>
            </p>
            <p>
              <i class="fa-solid fa-envelope"></i>
              &nbsp;  {previousProfileJobData?.restroDetails?.email}
            </p>
            <p>
              <i class="fa-solid fa-phone"></i> +44  {previousProfileJobData?.restroDetails?.restaurantContact_number}
            </p>
          </div>
          <div className="row mb-5">
            <div className="col">
              <div className="buttonsRow">
                <div className="navBtn"></div>
                <div className="btnGroups">
                  <button
                    className="btn btn-main btn-height"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                   Rejoin Pub
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body ">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
              </div>
              <h3>Rejoin pub request</h3>
              <div className="otpBox">
                <p>Are you want to rejoin the pub</p>
              </div>
              <button
                className="btn btn-main btn-height "
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={rejoinButtonhandler}
              >
                Yes
              </button>
              &nbsp;
              <button
                className="btn btn-white-outline btn-height"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPreviousJobProfile;
